import styled from 'styled-components';
import { Modal as AntModal } from 'antd';

export const StyledModal = styled(AntModal)`
  & .ant-modal-close {
    margin-top: 3px;
    margin-right: 8px;
  }

  & .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
