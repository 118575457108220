export enum agentColors {
  pageBackground = '#F8FCFC',
  darkMint = '#078768',
  mintWithOpacity = 'rgba(0, 208, 163, 0.1)',
}

export enum clientColors {
  pageBackground = '#F4F6F8',
  stepColor = '#00D0A3',
  dashboardWidgetAgent = '#24B2EF',
  dashboardWidgetPlatformMinutes = '#4377FD',
  dashboardWidgetPlatformSpend = '#19A684',
  projectSettingNavBarBackground = '#EDEDFF',
}

export enum extraColors {
  recordHover = '#F96666',
  greenGradient = 'linear-gradient(180deg, #0DB48C 0%, #088B6C 100%)',
  greenGradientAlt = ' linear-gradient(18.95deg, #01D1A4 8.03%, #01D1A4 51.35%, #D2F9F0 102.18%)',
  disabled = '#DADADA',
  lightBlue = '#14AEF0',
  surgePayLabel = '#97EEE5',
  starAlgorithmButtonColor = '#8E949C',
}

enum baseColors {
  black = '#000000',
  primaryButton = '#02BA91',
  primaryAction = '#203D50',
  primaryActionHover = '#215B81',
  primaryActionHoverWithOpacity = 'rgba(33, 91, 129, 0.1)',
  secondaryAction = '#6E818D',
  text = '#202629',
  secondaryText = '#5A6369',
  dividers = '#DAE4EA',
  background = '#F2F6F8',
  errors = '#B64E2D',
  errorWithOpacity = 'rgba(182,78,45, 0.1)',
  red = '#FF0000',
  alertWarning = '#B64E2D',
  alertWarningHover = '#CE5934',
  alertAdvice = '#EC9C00',
  purple = '#C97DFF',
  // DEPRECATED: remove these colors once we clean it up in agent portal
  primary = '#078768',
  primaryMedium = '#08A17C',
  primaryLight = '#00D0A3',
  secondary = '#203D50',
  secondaryMedium = '#6E818D',
  secondaryLight = '#A5B1B9',
  linkHover = '#2A5069',
  toggleInactive = '#C7C8C9',
  neutral00 = '#FFFFFF',
  neutral10 = '#F2F6F8',
  neutral20 = '#ECF4F5',
  neutral30 = '#F5FCFB',
  neutral40 = '#B8B8B8',
  neutral60 = '#5A6369',
  neutral80 = '#666666',
  neutral100 = '#202629',
  indigo100 = '#CBE9E9',
}

export default baseColors;
