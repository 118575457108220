import React from 'react';
import {
  TypographyRoot,
  TypographyElementMapping,
  TypographyElement,
  defaultVariantElementMapping,
  Variant,
  AlignKey,
  Margins,
  FontWeight,
} from './styledComponents';

export type TypographyProps = {
  align?: AlignKey;
  color?: string;
  weight?: FontWeight;
  variant?: Variant;
  play?: Variant;
  margin?: number | Margins;
  component?: TypographyElement;
};

const Typography: React.FC<
  React.HTMLAttributes<HTMLSpanElement> & TypographyProps
> = ({
  children,
  align = 'inherit',
  color,
  weight = 'medium',
  variant = 'textXl',
  margin,
  component,
  ...props
}) => {
  const { className } = props;
  const componentMapping = component && TypographyElementMapping[component];
  const Component = componentMapping || defaultVariantElementMapping[variant];
  const combineClassNames = className ? `${className} ${variant}` : variant;

  return (
    <TypographyRoot
      as={Component}
      {...props}
      $align={align}
      $color={color}
      $weight={weight}
      $variant={variant}
      $margin={margin}
      className={combineClassNames}
    >
      {children}
    </TypographyRoot>
  );
};

export default React.memo(Typography);
