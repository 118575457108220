import { FC, memo, ReactNode } from 'react';
import { Popover as AntdPopover, PopoverProps } from 'antd';

type Props = PopoverProps & {
  children: JSX.Element;
  content: ReactNode;
};

const Popover: FC<Props> = ({
  content,
  children,
  trigger = 'hover',
  ...props
}) => {
  return (
    <AntdPopover
      content={content}
      placement="rightTop"
      trigger={trigger}
      {...props}
    >
      {children}
    </AntdPopover>
  );
};

export default memo(Popover);
