import styled from 'styled-components';

export const StyledBadge = styled.div<{
  backgroundColor?: string;
}>`
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor}`};
  border-radius: 16px;
  padding: 4px 12px;
  display: inline-block;
`;
