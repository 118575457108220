import axios from 'axios';
import { auth } from 'shared/firebase';
import { isDev, isStage } from 'shared/utils/consts';
import { API_BASE_URL, FIREBASE_FUNCTIONS_BASE_URL } from './consts';

const getWalletBaseURL = () =>
  isDev()
    ? 'https://ao-wallet-7zkcit7loa-uc.a.run.app'
    : isStage()
    ? 'https://ao-wallet-kkwhngkz5q-uc.a.run.app'
    : 'https://ao-wallet-7hs56lngvq-uc.a.run.app';

export const walletBaseAPI = axios.create({
  baseURL: getWalletBaseURL(),
  headers: {
    'Content-Type': 'application/json',
  },
});
walletBaseAPI.interceptors.request.use(async (req) => {
  const token = await auth.currentUser?.getIdToken();
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }

  return req;
});

export const getShiftsBaseURL = () =>
  isDev()
    ? 'https://ao-shift-7zkcit7loa-uc.a.run.app'
    : isStage()
    ? 'https://ao-shift-kkwhngkz5q-uc.a.run.app'
    : 'https://ao-shift-7hs56lngvq-uc.a.run.app';

export const shiftsBaseAPI = axios.create({
  baseURL: getShiftsBaseURL(),
  headers: {
    'Content-Type': 'application/json',
  },
});
shiftsBaseAPI.interceptors.request.use(async (req) => {
  const token = await auth.currentUser?.getIdToken();
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }

  return req;
});

/** Auth0 management API */
export const MANAGEMENT_API_URL = (() => {
  return `${FIREBASE_FUNCTIONS_BASE_URL}/management-api`;
})();

export const auth0ManagementAPI = axios.create({
  baseURL: MANAGEMENT_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// auth0ManagementAPI.interceptors.request.use(async (req) => {});

export const EXCHANGE_FOR_FIREBASE_TOKEN_API_URL = (() => {
  // TODO: use "authentication-api/getToken", remember to take token from response.data.firebaseCustomToken
  // return `${BASE_URL}/authentication-api/getToken`;
  return `${FIREBASE_FUNCTIONS_BASE_URL}/get-firebase-custom-token`;
})();

export const apiGatewayAxios = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
apiGatewayAxios.interceptors.request.use(async (req) => {
  const firebaseJwtToken = await auth.currentUser?.getIdToken();
  if (firebaseJwtToken) {
    req.headers.Authorization = `Bearer ${firebaseJwtToken}`;
  }

  return req;
});

export const axiosJson = axios.create({
  timeout: 3000, // maybe this value is adjustable later
  headers: {
    'Content-Type': 'application/json',
  },
});
