import { format } from 'date-fns';
import { ColumnType } from 'antd/lib/table';
import { ISOCountry, ISOCountryKeys } from '@agentsonly/models';
import { Typography } from 'shared/ui';
import { US_DATE_FORMAT } from 'shared/utils';

import {
  ISOCurrency,
  currencyIdentifiers,
  getFormattedMoney,
} from './currency';

export const renderDate: ColumnType<Date> = {
  title: 'Date',
  dataIndex: 'date',
  key: 'date',
  render: (date: Date): JSX.Element => (
    <Typography component="span" variant="inputText">
      {date ? format(date, US_DATE_FORMAT) : '--'}
    </Typography>
  ),
};

export const renderNumberOfCalls: ColumnType<number> = {
  title: 'No. of Contacts/Shifts',
  dataIndex: 'calls',
  key: 'calls',
  render: (number: number): JSX.Element => (
    <Typography component="span" variant="inputText">
      {number ?? '--'}
    </Typography>
  ),
};

const renderPriceValues =
  (currency: ISOCurrency) =>
  (earning: number): JSX.Element => (
    <Typography component="span" variant="inputText">
      {getFormattedMoney(earning, currency)}
    </Typography>
  );

export const renderAmount = (countryCode: ISOCountry = ISOCountryKeys.US) => ({
  title: 'Amount',
  dataIndex: 'amount',
  key: 'amount',
  render: renderPriceValues(currencyIdentifiers[countryCode]),
});
