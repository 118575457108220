const shadows = {
  card: '0px 0px 2px rgba(110, 129, 141, 0.1), 0px 4px 4px rgba(110, 129, 141, 0.05)',
  subtle:
    ' 0px 0px 4px rgba(52, 81, 75, 0.05), 0px 12px 24px rgba(45, 123, 106, 0.05)',
  sm: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
  xl: '0px 20px 24px -4px rgba(16, 24, 40, 0.10), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)',
  indigo: `0px 16.563px 27.393px 0px rgba(65, 50, 255, 0.09),
    0px 8.6px 13.975px 0px rgba(65, 50, 255, 0.07),
    0px 3.504px 7.007px 0px rgba(65, 50, 255, 0.05),
    0px 0.796px 3.384px 0px rgba(65, 50, 255, 0.03)`,
};

export default shadows;
