import styled from 'styled-components';
import { baseColors } from 'shared/ui/theme';
import {
  HeroTitle,
  BigTitle,
  Title,
  Subtitle,
  SmallTitle,
  LabelTitle,
  XsTitle,
  XxsTitle,
  XxxsTitle,
  LargeText,
  MediumText,
  InputText,
  MediumTextBody,
  TextBody,
  SmallText,
  ExtraSmallText,
} from './styles';

export const TypographyElementMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p: 'p',
  span: 'span',
  blockquote: 'blockquote',
  em: 'em',
  strong: 'strong',
  b: 'b',
} as const;

export type TypographyElement = keyof typeof TypographyElementMapping;

// Mapping from Figma AO Design System UI Kit Typography section
const VariantMapping = {
  hero: HeroTitle,
  big: BigTitle,
  title: Title,
  subtitle: Subtitle,
  small: SmallTitle,
  label: LabelTitle,
  xs: XsTitle,
  xxs: XxsTitle,
  xxxs: XxxsTitle,
  largeText: LargeText,
  mediumText: MediumText,
  inputText: InputText,
  mediumTextBody: MediumTextBody,
  bodyText: TextBody,
  smallText: SmallText,
  extraSmallText: ExtraSmallText,
} as const;

export type Variant = keyof typeof VariantMapping;

export const defaultVariantElementMapping: Record<Variant, TypographyElement> =
  {
    hero: 'h1',
    big: 'h1',
    title: 'h2',
    subtitle: 'h3',
    small: 'h4',
    label: 'h5',
    xs: 'h6',
    xxs: 'h6',
    xxxs: 'h6',
    largeText: 'p',
    mediumText: 'p',
    inputText: 'p',
    mediumTextBody: 'p',
    bodyText: 'p',
    smallText: 'p',
    extraSmallText: 'p',
  };

export type AlignKey = 'center' | 'inherit' | 'justify' | 'left' | 'right';

type Margin = number | string;
export type Margins = [Margin, Margin?, Margin?, Margin?];

const getMargins = (margins: number | Margins) => {
  if (typeof margins === 'number') {
    return `margin: ${margins}px`;
  }

  const result = margins.reduce<string[]>((acc, cur) => {
    if (typeof cur === 'string') {
      return [...acc, cur];
    }

    return [...acc, `${cur}px`];
  }, []);

  return `margin: ${result.join(' ')}`;
};

export const TypographyRoot = styled.span<{
  $align?: AlignKey;
  $color?: string;
  $variant?: Variant;
  $margin?: number | Margins;
}>`
  text-align: ${({ $align }) => $align};
  color: ${({ $color }) => $color ?? baseColors.neutral100};
  ${({ $margin }) => {
    if (!$margin) {
      return 'margin: 0';
    }

    return getMargins($margin);
  }};

  ${({ $variant }) => $variant && VariantMapping[$variant]}
`;
