import styled from 'styled-components';
import Icon from '@ant-design/icons';

export const StyledIcon = styled(Icon)<{
  $stroke: string;
  $disabled?: boolean;
}>`
  path {
    stroke: ${({ $stroke }) => $stroke};
  }

  &&& {
    cursor: ${({ $disabled }) => $disabled && 'not-allowed'};

    &.anticon:not(.ant-select-suffix) {
      pointer-events: inherit;
    }
  }
`;
