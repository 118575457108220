import React from 'react';
import Icon, {
  PauseOutlined,
  CaretRightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

import { ReactComponent as MapPin } from './icons/map_pin.svg';

const DEFAULT_ICON_SIZE = 20;

type Props = {
  color?: string;
  size?: number;
};

export const MapPinIcon = ({ color, size }: Props) => (
  <Icon
    component={MapPin}
    style={{ color, fontSize: size ?? DEFAULT_ICON_SIZE }}
  />
);

export const PauseIcon = ({ color, size }: Props) => (
  <PauseOutlined style={{ color, fontSize: size ?? DEFAULT_ICON_SIZE }} />
);

export const PlayIcon = ({ color, size }: Props) => (
  <CaretRightOutlined style={{ color, fontSize: size ?? DEFAULT_ICON_SIZE }} />
);

export const CloseCircle = ({ color, size }: Props) => (
  <CloseCircleOutlined style={{ color, fontSize: size ?? DEFAULT_ICON_SIZE }} />
);

export const CheckCircle = ({ color, size }: Props) => (
  <CheckCircleOutlined style={{ color, fontSize: size ?? DEFAULT_ICON_SIZE }} />
);
