import React from 'react';
import { BaseIcon } from 'client-web/themes/v2/components/BaseIcon';
import { ButtonContainer } from './styledComponents';

type PaginationButtonProps = {
  direction: 'next' | 'previous';
};

const PaginationButton: React.FC<PaginationButtonProps> = ({ direction }) => (
  <ButtonContainer className="pagination-button">
    {direction === 'previous' && <BaseIcon icon="arrowLeft" size={16} />}
    {direction === 'next' && <BaseIcon icon="arrowRight" size={16} />}
  </ButtonContainer>
);

export default React.memo(PaginationButton);
