import React from 'react';
import {
  TypographyRoot,
  TypographyElementMapping,
  TypographyElement,
  defaultVariantElementMapping,
  Variant,
  AlignKey,
  Margins,
} from './styledComponents';

type TypographyProps = {
  align?: AlignKey;
  color?: string;
  variant?: Variant;
  margin?: number | Margins;
  component?: TypographyElement;
};

const Typography: React.FC<
  React.HTMLAttributes<HTMLSpanElement> & TypographyProps
> = ({
  children,
  align = 'inherit',
  color,
  variant = 'bodyText',
  margin,
  component,
  ...props
}) => {
  const { className } = props;
  const componentMapping = component && TypographyElementMapping[component];
  const Component =
    componentMapping || defaultVariantElementMapping[variant] || 'span';
  const combineClassNames = className ? `${className} ${variant}` : variant;

  return (
    <TypographyRoot
      as={Component}
      {...props}
      $align={align}
      $color={color}
      $variant={variant}
      $margin={margin}
      className={combineClassNames}
    >
      {children}
    </TypographyRoot>
  );
};

export default React.memo(Typography);
