export const Scales = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  // deprecated
  xl: 'xl',
  xxl: 'xxl',
} as const;

export type ScaleType = (typeof Scales)[keyof typeof Scales];

export const ButtonShape = {
  ROUND: 'round',
  SQUARE: 'square',
} as const;

export type ButtonShapeType = (typeof ButtonShape)[keyof typeof ButtonShape];

export const ButtonVariant = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  LINK: 'link',
} as const;

export type ButtonVariantType =
  (typeof ButtonVariant)[keyof typeof ButtonVariant];

export const ButtonColor = {
  BRAND: 'brand',
  GRAY: 'gray',
  DESTRUCTIVE: 'destructive',
} as const;

export type ButtonColorType = (typeof ButtonColor)[keyof typeof ButtonColor];
