import Hotjar from '@hotjar/browser';
import amplitude from 'amplitude-js';
import { User } from 'firebase/auth';
import { Agent } from '@agentsonly/models';
import ClientUser from 'shared/models/ClientUser';
import { config as trackingConfig } from 'shared/utils/trackingConfig';

export const init = (): void => {
  if (trackingConfig.amplitudeApiKey) {
    amplitude.getInstance().init(trackingConfig.amplitudeApiKey);
  }

  if (trackingConfig.hotjarID) {
    const HOTJAR_TRACKING_VER = 6;

    Hotjar.init(trackingConfig.hotjarID, HOTJAR_TRACKING_VER);
  }
};

export const setUserDevice = (installationToken: string): void => {
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setUserId = (userId: string | null): void => {
  amplitude.getInstance().setUserId(userId);

  if (trackingConfig.hotjarID) {
    Hotjar.identify(userId, {});
  }
};

export const setUserProperties = (user: User): void => {
  amplitude.getInstance().setUserProperties({
    email: user.email,
  });
};

export const setAgentProperties = (agent: Agent): void => {
  const userProperties = {
    email: agent.email,
    firstName: agent.firstName,
    lastName: agent.lastName,
    username: agent.userName,
  };
  amplitude.getInstance().setUserProperties(userProperties);

  if (trackingConfig.hotjarID) {
    Hotjar.identify(agent.id, userProperties);
  }
};

export const setPMProperties = (pm: ClientUser): void => {
  const userProperties = {
    email: pm.email,
    firstName: pm.firstName,
    lastName: pm.lastName,
  };

  amplitude.getInstance().setUserProperties(userProperties);

  if (trackingConfig.hotjarID) {
    Hotjar.identify(pm.id, userProperties);
  }
};

/** send tracking data */
export const sendData = (eventType: string, eventProperties?: any): void => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
  console.log('logged - ', eventType);
};

export const PROP_PROJECT_ID = 'project_id';
export const PROP_CONTACT_ID = 'contact_id';

// convention: present tense verb + object
// https://help.amplitude.com/hc/en-us/articles/115000465251-Data-Taxonomy-Playbook#h_437a698b-bcc5-470b-aac3-ef87dfb59221
// agents
export const A_CLICK_PROJECT_INFO_BUTTON = 'click project info button';

// Agent Portal
// Onboarding

export const A_CLICK_ONBOARDING_BECOMEAGENT = 'Onboarding_BecomeAgent_Click';
export const A_VIEW_ONBOARDING_PERSONALINFO = 'Onboarding_PersonalInfo_View';
export const A_CLICK_ONBOARDING_PERSONALINFO =
  'Onboarding_PersonalInfoSubmit_Click';
export const A_VIEW_ONBOARDING_FINISH_PAPER_WORK =
  'Onboarding_FinishPaperwork_View';
export const A_CLICK_ONBOARDING_FINISH_PAPER_WORK =
  'Onboarding_FinishPaperwork_Click';
export const A_VIEW_ONBOARDING_LEGAL_AGREEMENT =
  'Onboarding_LegalAgreement_View';
export const A_CLICK_ONBOARDING_LEGAL_AGREEMENT =
  'Onboarding_LegalAgreement_Click';
export const A_VIEW_ONBOARDING_CONFIRM_EMAIL = 'Onboarding_ConfirmEmail_View';
export const A_CLICK_ONBOARDING_RESEND_EMAIL = 'Onboarding_ConfirmEmail_Click';
export const A_VIEW_ONBOARDING_VERIFY_EMAIL =
  'Onboarding_EmailVerification_View';
// Gig Requirement
export const A_VIEW_BANK_DETAILS = 'GigReq_BankDetails_View';
export const A_SUBMIT_BANK_DETAILS = 'GigReq_BankDetailsSubmit_Click';
export const A_VIEW_VOICE_RECORDING = 'GigReq_Voice_View';
export const A_CLICK_START_RECORDING_BUTTON = 'GigReq_VoiceStart_Click';
export const A_CLICK_VOICE_RECORD_BUTTON = 'GigReq_VoiceRecord_Click';
export const A_CLICK_STOP_VOICE_RECORD_BUTTON = 'GigReq_VoiceStop_Click';
export const A_CLICK_PLAY_RECORDING_BUTTON = 'GigReq_VoicePlay_Click';
export const A_CLICK_CLOSE_VOICE_RECORDING_MODAL = 'GigReq_VoiceClose_Click';
export const A_SUBMIT_VOICE_RECORD = 'GigReq_VoiceSubmit_Click';
export const A_VIEW_SPEED_TEST = 'GigReq_SpeedTest_View';
export const A_CLICK_SPEED_TEST_START_BUTTON = 'GigReq_SpeedTestStart_Click';
export const A_VIEW_SPEED_TEST_RESULT = 'GigReq_SpeedTestResult_View';
export const A_CLICK_SPEED_TEST_FINISH = 'GigReq_SpeedTestSubmit_Click';
export const A_VIEW_PC_TEST = 'GigReq_PCTest_View';
export const A_CLICK_PC_TEST_OPEN_MODAL = 'GigReq_PCTestStart_Click';
export const A_CLICK_PC_TEST_DOWNLOAD_BUTTON = 'GigReq_PCTestLaunch_Click';
export const A_VIEW_PC_TEST_RESULT = 'GigReq_PCTestResult_View';
export const A_CLICK_PC_TEST_FINISH = 'GigReq_PCTestSubmit_Click';
export const A_VIEW_GIG_REQUIREMENT_COMPLETE = 'GigReq_Completed';

// Project
export const A_VIEW_PROJECTS = 'Projects_View';
export const A_CLICK_ONGOING_PROJECTS = 'Ongoing_Projects_Click';
export const A_CLICK_AVAILABLE_PROJECTS = 'Available_Projects_Click';
export const A_CLICK_JOINED_PROJECTS = 'Joined_Projects_Click';
export const A_CLICK_PENDING_PROJECTS = 'Pending_Projects_Click';
export const A_CLICK_PROJECTS_SORT = 'Projects_Sort_Click';
export const A_CLICK_GO_PROJECT = 'Go_Project_Click';
export const A_CLICK_APPLY_PROJECT = 'Apply_Project_Click';
export const A_CLICK_SKILL_TEST = 'Skill_Test_Click';
export const A_CLICK_PROJECT_SKILL_TEST_START = 'Project_SkillsTestStart_Click';
export const A_CLICK_PROJECT_SKILL_TEST_BACK = 'Project_SkillsTestBack_Click';
export const A_CLICK_PROJECT_SKILL_TEST_DONE = 'Project_SkillsTestDone_Click';
export const A_VIEW_PROJECT_ACTIVE_PROJECT = 'Project_ActiveProject_Views';
export const A_CLICK_PROJECT_TAKE_CALLS = 'Project_TakeCalls_Click';
export const A_CLICK_PROJECT_CHANGE = 'Project_Change_Click';
export const A_VIEW_PROJECT_CALL_HISTORY = 'Project_CallHistory_View';
export const A_VIEW_PROJECT_INVOICE = 'Project_Invoices_View';
export const A_VIEW_PROJECT_CHAT = 'Project_Chat_View';
export const A_VIEW_PROJECT_DETAIL = 'Project_Details_View';
export const A_VIEW_PROJECT_PAY = 'Project_Pay_View';
export const A_VIEW_PROJECT_FIND_PROJECT = 'Project_FindProject';
export const A_VIEW_CALL_MODAL = 'Call_Modal_View';
export const A_VIEW_PROJECT_STAR_RATING = 'Project_StarRating_View';
export const A_CLICK_ASK_MAI = 'Project_AskMAI_click';
export const A_CLICK_ASK_MAI_MESSAGE_SENT = 'Project_AskMAI_Message_Sent';
export const A_CLICK_ASK_MAI_EXPAND_CHAT = 'Project_AskMAI_Expand_Chat';
export const A_CLICK_ASK_MAI_RESET_CHAT = 'Project_AskMAI_Reset_Chat';
export const A_CLICK_ASK_MAI_CLOSE_CHAT = 'Project_AskMAI_Close_Chat';

// Tooltips
export const A_VIEW_TOOLTIP_WELCOME = 'ToolTip_Welcome_View';
export const A_CLICK_TOOLTIP_WELCOME_SKIP = 'ToolTip_WelcomeSkip_Click';
export const A_CLICK_TOOLTIP_WELCOME_NEXT = 'Tooltip_WelcomeNext_Click';
export const A_CLICK_TOOLTIP_WELCOME_CLOSE = 'Tooltip_WelcomeClose_Click';

// Global Menu
export const A_CLICK_SCHEDULE_TAB = 'Schedule_Tab_Click';
export const A_CLICK_INSIGHTS_TAB = 'Insights_Tab_Click';
export const A_CLICK_REFERRALS_TAB = 'Referrals_Tab_Click';
export const A_CLICK_ACCOUNT_SETTINGS_TAB = 'Account_Tab_Click';
export const A_CLICK_KNOWLEDGE_LAB_TAB = 'Knowledge_Lab_Tab_Click';
export const A_CLICK_PROJECT_TAB = 'Project_Tab_Click';
export const A_CLICK_WALLET_TAB = 'Wallet_Tab_Click';
export const A_CLICK_HOME_TAB = 'Home_Tab_Click';
export const A_CLICK_COACHING_TAB = 'Coaching_Tab_Click';
// Schedule
export const A_VIEW_SCHEDULE = 'Schedule_Page_View';
// Coaching
export const A_VIEW_COACHING = 'Coaching_Page_View';
// Insights
export const A_VIEW_INSIGHTS = 'Inisghts_Page_View';
// Referrals
export const A_VIEW_REFERRALS = 'Referrals_Page_View';
export const A_CLICK_INVITE_FRIEND_BUTTON = 'Referrals_InviteFriend_Click';
// Account Settings
export const A_VIEW_ACCOUNT_SETTINGS = 'Account_Page_View';

export const A_CLICK_AI_CHAT_MESSAGE_SEND = 'Send_AI_Message_Click';

// clients side
export const C_VIEW_DASHBOARD = 'view dashboard';
export const C_VIEW_PAY_DETAILS = 'view pay details';
export const C_VIEW_DAILY_REPORTS = 'view daily reports';
export const C_VIEW_STAR_ALGORITHM = 'view star algorithm';
export const C_VIEW_AGENTS = 'view agents';
export const C_VIEW_CHATS = 'view chats';
export const C_VIEW_SETTINGS = 'view settings';
export const C_VIEW_REQUIREMENTS = 'view requirements';
export const C_VIEW_TRAINING = 'view training';
export const C_VIEW_LEGAL = 'view legal';
export const C_VIEW_PROFILE = 'view profile';

// wallet
export const A_WALLET_VIEW = 'view wallet';
export const A_WALLET_WITHDRAW_CLICK = 'wallet withdraw click';
export const A_WALLET_ERROR1_VIEW = 'withdraw error 1';
export const A_WALLET_ERROR2_VIEW = 'withdraw error 2';
export const A_WALLET_ERROR3_VIEW = 'withdraw error 3';
export const A_WALLET_ERROR4_VIEW = 'withdraw error 4';
export const A_WALLET_ERROR5_VIEW = 'withdraw error 5';

export const handleClickWithTracking =
  <T extends any[]>(eventName: string, handler?: (...args: T) => void) =>
  (...args: T) => {
    sendData(eventName);
    handler?.(...args);
  };

export const handleClickWithTrackingProps =
  <T extends any[]>(
    eventName: string,
    eventProperties: any,
    handler?: (...args: T) => void,
  ) =>
  (...args: T) => {
    sendData(eventName, eventProperties);
    handler?.(...args);
  };
