import { memo } from 'react';
import { LoadingContainer } from 'shared/ui/theme';
import { DEFAULT_NUM_OF_DOT, StyledSpinner } from './styledComponents';

type Props = {
  height?: string;
  scale?: number;
};

const DEFAULT_HEIGHT = '100vh';
const DEFAULT_SPINNER_SCALE = 0.4;

export const Spinner: React.FC<Props> = memo(
  ({ height = DEFAULT_HEIGHT, scale = DEFAULT_SPINNER_SCALE }) => {
    return (
      <LoadingContainer $height={height}>
        <StyledSpinner role="progressbar" $scale={scale}>
          {Array.from({ length: DEFAULT_NUM_OF_DOT }, (_, key) => (
            <div key={key} />
          ))}
        </StyledSpinner>
      </LoadingContainer>
    );
  },
);
