const Button = {
  primary: {
    default: '#6359E9',
    hover: '#7C74E7',
    focus: '#7C74E7',
  },
  alert: {
    default: '#B64E2D',
    hover: '#CE5934',
    focus: '#CF5934',
  },
  link: {
    hover: '#2A5069',
    focus: '#2A5069',
  },
} as const;

export default Button;
