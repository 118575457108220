import React from 'react';
import { ModalProps } from 'antd/lib/modal';
import { ClientWizardCloseIcon } from '../../assets';
import { StyledModal } from './styledComponents';

type Props = {
  children: React.ReactNode | React.ReactNodeArray | null;
  width?: string | number;
};

const Modal: React.FC<ModalProps & Props> = ({ children, width, ...props }) => {
  return (
    <StyledModal
      {...props}
      closeIcon={<ClientWizardCloseIcon />}
      width={width || 600}
    >
      {children}
    </StyledModal>
  );
};

export default React.memo(Modal);
