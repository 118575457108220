import React from 'react';
import { ButtonProps as BaseButtonProps } from 'antd/lib/button';
import { RoundStyle, SquareStyle } from './styledComponents';
import {
  ButtonShape,
  Scales,
  ScaleType,
  ButtonShapeType,
  ButtonVariantType,
  ButtonVariant,
  ButtonColorType,
  ButtonColor,
} from './types';
import { getVariantColorProps } from './utils';

/**
 * TODO: Design System (Ref: https://www.figma.com/file/XBlYQK8vPfsP5saOOQyoGJ/AO-Design-System-V2?node-id=1038%3A34411&t=eyjmvwZMLIU2jR4s-0)
 * Button has some pattern
 * Currently, just make round one because other button is not used yet
 * Guess we need to add custom props and create another styling
 */
type Props = BaseButtonProps & {
  color?: ButtonColorType;
  scale?: ScaleType;
  minWidth?: number | string;
  buttonShape?: ButtonShapeType;
  className?: string;
  variant?: ButtonVariantType;
};

const ButtonComponentMaps = {
  [ButtonShape.ROUND]: RoundStyle,
  [ButtonShape.SQUARE]: SquareStyle,
};

const Button: React.FC<Props> = ({
  color = ButtonColor.BRAND,
  scale = Scales.lg,
  minWidth,
  buttonShape = ButtonShape.ROUND,
  variant = ButtonVariant.CONTAINED,
  className,
  ...props
}) => {
  const ButtonComponent = ButtonComponentMaps[buttonShape];
  const { focus, hover, disabled, ...baseProps } = getVariantColorProps(
    variant,
    color,
  );

  return (
    <ButtonComponent
      className={className}
      $scale={scale}
      $minWidth={minWidth}
      $disabledProps={disabled}
      $focusProps={focus}
      $hoverProps={hover}
      $baseProps={baseProps}
      {...props}
    />
  );
};

export default Button;
