import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Gray, Success } from '../../themes/colors';
import { BaseIcon } from '../../assets/icons';
import Typography from '../Typography';
import {
  ButtonContainer,
  PagerContainer,
  ResponsivePaginatedTypography,
} from './styledComponents';

type PaginationButtonProps = {
  direction: 'next' | 'previous';
  disabled?: boolean;
};

type PaginationPagerProps = {
  pageNum: number;
};

export const PaginationButton: React.FC<PaginationButtonProps> = ({
  direction,
  disabled,
}) => (
  <ButtonContainer $borderColor={disabled ? Gray[200] : Gray[300]}>
    {direction === 'previous' && (
      <BaseIcon stroke={disabled ? Gray[200] : Gray[700]} icon="arrowLeft" />
    )}
    <ResponsivePaginatedTypography
      variant="textSm"
      color={disabled ? Gray[300] : Gray[700]}
      $direction={direction}
    >
      <FormattedMessage
        id={
          direction === 'next'
            ? 'pagination.button.next'
            : 'pagination.button.prev'
        }
      />
    </ResponsivePaginatedTypography>
    {direction === 'next' && (
      <BaseIcon stroke={disabled ? Gray[200] : Gray[700]} icon="arrowRight" />
    )}
  </ButtonContainer>
);

export const PaginationPager: React.FC<PaginationPagerProps> = ({
  pageNum,
}) => (
  <PagerContainer>
    <Typography color={Success[900]} variant="textSm">
      {pageNum}
    </Typography>
  </PagerContainer>
);
