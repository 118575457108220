import React from 'react';
import { Spacer, Typography } from 'shared';
import { FormattedMessage } from 'react-intl';
import secondary from 'client-web/themes/v2/colors/secondary';
import { LoadMoreContainer, StyledButton } from './styledComponents';

type Props = {
  total: number;
  size: number;
  onClickHandler: () => void;
};

export const LoadMore: React.FC<Props> = ({ total, size, onClickHandler }) => {
  const showLoadMoreButton = size < total;
  return (
    <LoadMoreContainer align="middle">
      {showLoadMoreButton && (
        <>
          <StyledButton
            onClick={onClickHandler}
            minWidth={314}
            buttonType="brand"
            variant="outlined"
          >
            <Typography variant="mediumTextBody" color={secondary.medium['2']}>
              <FormattedMessage id="general.loadMore.button" />
            </Typography>
          </StyledButton>
          <Spacer size={24} axis="horizontal" />
        </>
      )}
      <Typography variant="mediumTextBody" color={secondary.medium['2']}>
        <FormattedMessage
          id="general.loadMore.displayText"
          values={{
            rows: size,
            total,
          }}
        />
      </Typography>
    </LoadMoreContainer>
  );
};
