// TODO: https://agentsonly.atlassian.net/browse/AOP-3914
import React from 'react';
import type { PaginationProps } from 'antd';
import { PaginationButton, PaginationPager } from './components';
import { StyledPagination } from './styledComponents';

type PaginationOptions = {
  showSizeChanger?: boolean;
  pageSizeOptions?: string[];
  hideOnSinglePage?: boolean;
  defaultPageSize?: number;
  onShowSizeChange?: (current: number, size: number) => void;
};

const itemRender: PaginationProps['itemRender'] = (
  pageNum,
  type,
  originalElement,
) => {
  if (type === 'prev') {
    return <PaginationButton disabled={pageNum === 1} direction="previous" />;
  }

  if (type === 'next') {
    return <PaginationButton direction="next" />;
  }

  if (type === 'page') {
    return <PaginationPager pageNum={pageNum} />;
  }

  return originalElement;
};

type Props = {
  current: number;
  pageSize: number;
  total: number;
  onChange: (page: number) => Promise<void> | void;
  paginationOptions?: PaginationOptions;
  showLessItems?: boolean;
};

const Pagination: React.FC<Props> = ({
  current,
  pageSize,
  total,
  onChange,
  paginationOptions,
  showLessItems = false,
}) => (
  <StyledPagination
    current={current}
    pageSize={pageSize}
    total={total}
    onChange={onChange}
    showSizeChanger={false}
    itemRender={itemRender}
    showLessItems={showLessItems}
    className={`${showLessItems ? 'narrow' : 'wide'}`}
    {...paginationOptions}
  />
);

export default React.memo(Pagination);
