import styled from 'styled-components';
import { baseColors } from '../../theme';

export const Dot = styled.span<{ color?: string; $size?: number }>`
  height: ${({ $size }) => $size || 4}px;
  width: ${({ $size }) => $size || 4}px;
  background-color: ${({ color }) => color || baseColors.text};
  border-radius: 50%;
  display: inline-block;
`;
