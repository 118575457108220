import { Modal as AntModal } from 'antd';
import styled from 'styled-components';
import { baseColors } from 'shared/ui/theme';

export const ModalBodyWrapper = styled.div`
  position: relative;
  border-top: 1px solid ${baseColors.dividers};
  display: flex;
  justify-content: center;
  padding-top: 64px;
  overflow-y: auto;
  min-height: calc(100% - 65px); // padding + border = 65px
`;

export const RocketWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
`;

export const CirclesWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
`;

export const ModalBody = styled.div`
  z-index: 2;
`;

export const Modal = styled(AntModal)<{ $headerHeight: number }>`
  & {
    top: 0px;
    min-width: 100%;
    margin: 0;
    padding-bottom: 0px;
    overflow: hidden;
  }

  .ant-modal-content {
    overflow: auto;
    min-height: 100vh;
  }

  .ant-modal-header {
    box-shadow:
      0px 4px 4px rgba(110, 129, 141, 0.05),
      0px 0px 2px rgba(110, 129, 141, 0.1);
    padding: 0;
  }

  .ant-modal-close-x {
    margin-left: 24px;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .ant-modal-close {
    width: 80px;
    height: 80px;
    top: 0;
    left: 0;
  }

  .ant-modal-body {
    position: absolute;
    width: 100%;
    height: ${({ $headerHeight }) => `calc(100% - ${$headerHeight}px)`};
    padding: 0;
    background: ${baseColors.background};
    overflow-y: auto;
  }
`;
