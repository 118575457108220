import { App } from 'antd';
import type { NotificationInstance } from 'antd/es/notification/interface';

// eslint-disable-next-line import/no-mutable-exports
let notification: NotificationInstance;

// Docs: @https://ant.design/components/app#global-scene-redux-scene
export default () => {
  const fn = App.useApp();
  notification = fn.notification;
  return null;
};
export { notification };
