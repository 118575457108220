import { Col, Table } from 'antd';
import styled from 'styled-components';
import { Background, Secondary } from 'client-web/themes/v2';

export const Container = styled(Col)`
  &.zebra-theme-blue
    .ant-table-wrapper
    .ant-table-tbody
    > tr.ant-table-row:hover
    > td.ant-table-cell-row-hover {
    background: ${Background.nav[1]};
  }

  &.zebra-theme-orange
    .ant-table-wrapper
    .ant-table-tbody
    > tr.ant-table-row:hover
    > td.ant-table-cell-row-hover {
    background: ${Background.nav[2]};
  }

  &.zebra-theme-green
    .ant-table-wrapper
    .ant-table-tbody
    > tr.ant-table-row:hover
    > td.ant-table-cell-row-hover {
    background: ${Background.nav[3]};
  }

  .ant-table-wrapper {
    width: 100%;
  }
`;

export const StyledTable = styled(Table)`
  .ant-table {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .ant-table-content {
    overflow-y: auto;
  }

  .ant-table-thead {
    & > tr > th {
      background: white;
      color: ${Secondary.medium[2]};

      &:not(:last-child):not(.ant-table-selection-column) {
        content: none;
      }

      &:not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        content: none;
      }

      &::before {
        display: none;
      }
    }

    & > tr > td {
      background: white;
      border-color: ${Secondary.medium[1]};
    }
  }

  .ant-table-container table > thead > tr:first-child {
    & th {
      height: 49px;
      border-color: ${Secondary.medium[1]};
      padding-top: 0px;
      padding-bottom: 0px;
    }
    & th:first-child {
      border-top-left-radius: 20px;
    }
    & th:last-child {
      border-top-right-radius: 20px;
    }
  }
  .ant-table-container table > tbody > tr:last-child {
    & td {
      border-color: ${Secondary.medium[1]};
    }

    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-cell {
    height: 64px;
    padding: 0px 32px;
  }
`;

export const EmptyTableContainer = styled.div`
  margin: 120px 0;
`;
