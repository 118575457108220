import { StyledIcon } from './styledComponents';
import { BaseIconComponentMaps, BaseIconProps } from './BaseIcon';
import { BaseIconTypes } from './baseIcon.types';

const DEFAULT_ICON_SIZE = 24;

// see @https://www.figma.com/file/vdOVQsSBnmL7LIAAX6FOD2/AO-Design-System---Client?node-id=617%3A349&t=bNqCqJ5pHEqLwH7t-0
export const BaseIcon = ({
  icon = BaseIconTypes.arrowDropDown,
  color = 'white',
  stroke = 'black',
  size = DEFAULT_ICON_SIZE,
  className,
  onClick,
  disabled,
  dataTestId,
  name,
}: BaseIconProps) => {
  const BaseIconComponent = BaseIconComponentMaps[icon];
  return (
    <StyledIcon
      name={name}
      className={className}
      component={BaseIconComponent}
      style={{
        color,
        fontSize: size,
      }}
      $stroke={stroke}
      $disabled={disabled}
      // Attach the handleClick function to the onClick event of the icon.
      // If the icon is disabled, it will be undefined, effectively disabling the click action.
      onClick={disabled ? undefined : onClick}
      data-testid={dataTestId}
    />
  );
};
