const shadows = {
  modal: {
    xl: '0 0 4px rgba(52, 81, 75, 0.05), 0 12px 24px rgba(45, 123, 106, 0.05)',
  },
  xs: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  lg: '0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)',
  xl: '0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)',
  xxl: '0px 24px 48px -12px rgba(16, 24, 40, 0.25)',
  sm: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
  md: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
};

export default shadows;
