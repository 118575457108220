import { useLocalStorage } from 'shared';
import { DEFAULT_PAGE_SIZE } from 'client-web/modules/agents/consts';
import { useCallback, useEffect, useReducer } from 'react';

interface TableProps {
  totalPages: number;
  loading: boolean;
  currentPage: number;
}

type Props = {
  data: any[];
  tableSizeKey: string;
  nextPage?: (page: number) => Promise<void>;
  resetPageNumberWithDataUpdate?: boolean;
  pagingSize?: number;
};

// TODO: review this hooks, there might be unnecessary deps
export const useTable = ({
  data,
  nextPage,
  resetPageNumberWithDataUpdate,
  pagingSize,
  tableSizeKey,
}: Props) => {
  const [tableSize, setTableSize] = useLocalStorage(
    tableSizeKey,
    pagingSize ?? DEFAULT_PAGE_SIZE,
  );

  const [table, updateTable] = useReducer(
    (prev: TableProps, next: Partial<TableProps>) => {
      return { ...prev, ...next };
    },
    {
      totalPages: 1,
      loading: true,
      currentPage: 1,
    },
  );

  useEffect(() => {
    const total = Math.ceil(data.length / tableSize);
    updateTable({ totalPages: total });
  }, [tableSize, data]);

  const onPageChange = useCallback(
    async (page: number) => {
      if (page > table.totalPages) {
        updateTable({ loading: true });
        nextPage?.(page)
          .then(() => {
            updateTable({ currentPage: page });
          })
          .catch((err) => console.error(err));
      } else {
        updateTable({ currentPage: page });
      }
    },
    [nextPage, table.totalPages],
  );

  useEffect(() => updateTable({ currentPage: 1 }), [tableSize]);
  useEffect(() => updateTable({ loading: false }), [data]);

  useEffect(() => {
    if (resetPageNumberWithDataUpdate) {
      updateTable({ currentPage: 1 });
    }
  }, [resetPageNumberWithDataUpdate, data]);

  return { tableSize, table, onPageChange, setTableSize };
};
