import { useState, useCallback } from 'react';

const useTextInput = (
  initialValue = '',
  setError?: (error: string) => void,
): [string, (newValue: string) => void] => {
  const [value, setValue] = useState(initialValue);

  const handleValueChange = useCallback(
    (newValue: string) => {
      setValue(newValue);
      if (setError) {
        setError('');
      }
    },
    [setError],
  );

  return [value, handleValueChange];
};

export default useTextInput;
