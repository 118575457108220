import styled, { keyframes } from 'styled-components';
import { Success } from '../../themes/colors';

export const DEFAULT_NUM_OF_DOT = 9;

const scaleInOut = keyframes`
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.7);
  }
`;

const generateDotStyles = (index: number) => {
  const backgroundColors = [
    '#00bf96',
    Success[400],
    Success[400],
    Success[300],
    Success[300],
    Success[300],
    Success[400],
    Success[400],
    '#00bf96',
  ];

  return `
    background-color: ${backgroundColors[index]};
    animation-delay: ${index * 0.125}s;
  `;
};

export const StyledSpinner = styled.div<{ $scale: number }>`
  display: grid;
  grid-template-columns: repeat(3, 20px);
  grid-template-rows: repeat(3, 20px);
  grid-gap: 5px;
  justify-items: center;
  align-items: center;

  ${({ $scale }) => $scale && `scale: ${$scale};`}

  div {
    width: 20px;
    height: 20px;
    border-radius: 100px;
    box-shadow: 0 0 5px rgba(68, 76, 231, 0.5);
    animation: ${scaleInOut} 1.5s infinite;
  }

  ${Array.from(
    { length: DEFAULT_NUM_OF_DOT },
    (_, index) => `div:nth-child(${index + 1}) { ${generateDotStyles(index)} }`,
  ).join('\n')}
`;
