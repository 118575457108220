import React, { ReactNode } from 'react';
import { LabelContainer } from './styledComponents';

type Props = {
  htmlFor?: string;
  label?: ReactNode;
  RightLabelComponent?: JSX.Element;
};

const InputLabel: React.FC<Props> = ({
  htmlFor,
  label,
  RightLabelComponent,
}) => {
  if (!label) {
    return null;
  }

  return (
    <LabelContainer>
      <label htmlFor={htmlFor}>{label}</label>
      {RightLabelComponent}
    </LabelContainer>
  );
};

export default React.memo(InputLabel);
