import React, { MouseEventHandler } from 'react';
import { Icon } from '../../assets';
import { IconContainer } from './styledComponents';

type Props = {
  icon: React.ReactElement<Icon>;
  onClick: MouseEventHandler;
  className?: string;
  htmlType?: 'submit' | 'reset' | 'button' | undefined;
};

const IconButton: React.FC<Props> = ({
  icon: ButtonIcon,
  onClick,
  className,
  htmlType,
}) => {
  return (
    <IconContainer
      type={htmlType ?? 'button'}
      onClick={onClick}
      className={className}
    >
      {ButtonIcon}
    </IconContainer>
  );
};

export default React.memo(IconButton);
