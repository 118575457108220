import { ISOCountry, ISOCountryKeys } from '@agentsonly/models';
import { round } from './number';
import { DEFAULT_EMPTY_VALUE } from './consts';

export enum ISOCurrency {
  CAD = 'CAD',
  MXN = 'MXN',
  PHP = 'PHP',
  USD = 'USD',
}

export const currencyIdentifiers: Record<ISOCountry, ISOCurrency> = {
  CA: ISOCurrency.CAD,
  MX: ISOCurrency.MXN,
  PH: ISOCurrency.PHP,
  US: ISOCurrency.USD,
};

const mapToCountryCode: Record<ISOCurrency, ISOCountry> = Object.entries(
  currencyIdentifiers,
).reduce(
  (acc, [k, v]) => {
    acc[v] = k as ISOCountry;
    return acc;
  },
  {} as Record<ISOCurrency, ISOCountry>,
);

export const currencySymbols: Record<ISOCurrency, string> = {
  CAD: '$',
  MXN: '$',
  PHP: '₱',
  USD: '$',
};

export const ISOFullCurrency: Record<ISOCountry, string> = {
  CA: `Canadian Dollar (${currencySymbols[ISOCurrency.CAD]})`,
  MX: `Mexican Peso (${currencySymbols[ISOCurrency.MXN]})`,
  PH: `Philippine Peso (${currencySymbols[ISOCurrency.PHP]})`,
  US: `US Dollar (${currencySymbols[ISOCurrency.USD]})`,
};

export enum BankIdentifier {
  CLABE = 'CLABE',
}

export const currencySymbolFromCountry = (country: ISOCountry) =>
  currencySymbols[currencyIdentifiers[country]];

const getFlooredFixed = (value: number, digit: number): string => {
  return (Math.floor(value * 10 ** digit) / 10 ** digit)
    .toFixed(digit)
    .replace('.00', '');
};

export const formatAmount = (amount?: string | number, places = 2): string => {
  if (!amount) return DEFAULT_EMPTY_VALUE;
  return typeof amount === 'number'
    ? round(amount, places).toFixed(places)
    : amount; // handle accidently passing a string
};

export const compactNumber = (
  rawNumber: string | number,
  decimal: number | undefined = 0,
): string => {
  const sign = Number(rawNumber) < 0 ? '-' : '';
  const num = Math.abs(Number(rawNumber));
  if (num >= 1000 && num < 1000000)
    return `${sign}${getFlooredFixed(num / 1000, decimal)}K`;

  if (num > 1000000)
    return `${sign}${getFlooredFixed(num / 1000000, decimal)}M`;
  return typeof rawNumber === 'number'
    ? getFlooredFixed(rawNumber, 0)
    : rawNumber; // handle accidently passing a string
};

/**
 * The function `getCurrencySymbol` returns the currency symbol based on the provided ISO country or
 * currency code.
 * @param {ISOCountry | ISOCurrency} [code=US] - The `code` parameter is of type `ISOCountry` or
 * `ISOCurrency`. It represents the ISO country code or ISO currency code for which you want to
 * retrieve the currency symbol.
 * @returns The function `getCurrencySymbol` returns the currency symbol for a given ISO country code
 * or ISO currency code.
 */
export const getCurrencySymbol = (code: ISOCountry | ISOCurrency = 'US') => {
  let countryCode: ISOCountry = (ISOCountryKeys as Record<string, ISOCountry>)[
    code
  ];
  if (!countryCode) countryCode = mapToCountryCode[code as ISOCurrency];
  const currency = countryCode ? currencySymbolFromCountry(countryCode) : '';

  return currency;
};

/**
 * The function `getFormattedMoney` takes an amount, currency code, and optional decimal places and
 * returns a formatted string with the currency symbol and amount.
 * @param {string | number | undefined} [amount=0] - The `amount` parameter is the value of the money
 * that you want to format. It can be a string, number, or undefined. If it is undefined, the default
 * value is set to 0.
 * @param {ISOCountry | ISOCurrency} [code=US] - The `currencyOrCountryCode` parameter is a string that represents
 * either an ISO country code or an ISO currency code. It is used to determine the currency symbol to
 * be used in the formatted money string.
 * @param {number} [places] - The `places` parameter is an optional parameter that specifies the number
 * of decimal places to display in the formatted amount. If `places` is not provided, the default value
 * is used.
 * @returns a string that represents the formatted amount of money, including the currency symbol.
 */
export const getFormattedMoney = (
  amount: string | number | undefined = 0,
  currencyOrCountryCode: ISOCountry | ISOCurrency = 'US',
  places?: number,
) => {
  const currency = getCurrencySymbol(currencyOrCountryCode);
  const formattedAmount = formatAmount(amount, places);
  return `${currency}${formattedAmount}`;
};

export const maskBankNumber = (number: string) => {
  return `xxxx-${number.slice(number.length - 4)}`;
};
