import { css } from 'styled-components';
import { lineHeight, rem } from 'shared/utils/styles';

// Title Style
export const HeroTitle = css`
  font-weight: 700;
  font-size: ${rem(50)};
  line-height: ${lineHeight(65.1, 50)};
`;

export const BigTitle = css`
  font-weight: 700;
  font-size: ${rem(32)};
  line-height: ${lineHeight(41.66, 32)};
`;

export const Title = css`
  font-weight: 700;
  font-size: ${rem(24)};
  line-height: ${lineHeight(31.25, 24)};
`;

export const Subtitle = css`
  font-weight: 700;
  font-size: ${rem(21)};
  line-height: ${lineHeight(27.34, 21)};
`;

export const SmallTitle = css`
  font-weight: 700;
  font-size: ${rem(18)};
  line-height: ${lineHeight(23.44, 18)};
`;

export const LabelTitle = css`
  font-weight: 700;
  font-size: ${rem(16)};
  line-height: ${lineHeight(20.83, 16)};
`;

export const XsTitle = css`
  font-weight: 700;
  font-size: ${rem(14)};
  line-height: ${lineHeight(18.23, 14)};
`;

export const XxsTitle = css`
  font-weight: 700;
  font-size: ${rem(12)};
  line-height: ${lineHeight(15.62, 12)};
`;

export const XxxsTitle = css`
  font-weight: 700;
  font-size: ${rem(10)};
  line-height: ${lineHeight(13.02, 10)};
`;

// Text Style
export const LargeText = css`
  font-size: ${rem(24)};
  line-height: ${lineHeight(31.25, 24)};
`;

export const MediumText = css`
  font-weight: 500;
  font-size: ${rem(18)};
  line-height: ${lineHeight(23.44, 18)};
`;

export const InputText = css`
  font-weight: 500;
  font-size: ${rem(16)};
  line-height: ${lineHeight(24, 16)};
`;

export const MediumTextBody = css`
  font-weight: 500;
  font-size: ${rem(14)};
  line-height: ${lineHeight(18.23, 14)};
`;

export const TextBody = css`
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: ${lineHeight(18.23, 14)};
`;

export const SmallText = css`
  font-weight: 500;
  font-size: ${rem(12)};
  line-height: ${lineHeight(15.62, 12)};
`;

export const ExtraSmallText = css`
  font-weight: 500;
  font-size: ${rem(10)};
  line-height: ${lineHeight(10, 13)};
`;
