import styled from 'styled-components';
import { Gray } from '../../colors';
import {
  Bold,
  Medium,
  Regular,
  TextLg,
  TextMd,
  TextSm,
  TextXl,
  TextXs,
  DisplayXxl,
  DisplayXl,
  DisplayLg,
  DisplayMd,
  DisplaySm,
  DisplayXs,
  TextXxs,
} from './styles';

export const TypographyElementMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p: 'p',
  span: 'span',
  blockquote: 'blockquote',
  em: 'em',
  strong: 'strong',
  b: 'b',
} as const;

export type TypographyElement = keyof typeof TypographyElementMapping;

const VariantMapping = {
  displayXxl: DisplayXxl,
  displayXl: DisplayXl,
  displayLg: DisplayLg,
  displayMd: DisplayMd,
  displaySm: DisplaySm,
  displayXs: DisplayXs,
  textXl: TextXl,
  textLg: TextLg,
  textMd: TextMd,
  textSm: TextSm,
  textXs: TextXs,
  textXxs: TextXxs,
} as const;

export type Variant = keyof typeof VariantMapping;

const FontWeightMapping = {
  regular: Regular,
  medium: Medium,
  bold: Bold,
} as const;

export type FontWeight = keyof typeof FontWeightMapping;

export const defaultVariantElementMapping: Record<Variant, TypographyElement> =
  {
    displayXxl: 'h1',
    displayXl: 'h2',
    displayLg: 'h3',
    displayMd: 'h4',
    displaySm: 'h5',
    displayXs: 'h6',
    textXl: 'p',
    textLg: 'p',
    textMd: 'p',
    textSm: 'p',
    textXs: 'p',
    textXxs: 'p',
  };

export type AlignKey = 'center' | 'inherit' | 'justify' | 'left' | 'right';

type Margin = number | string;
export type Margins = [Margin, Margin?, Margin?, Margin?];

const getMargins = (margins: number | Margins) => {
  if (typeof margins === 'number') {
    return `margin: ${margins}px`;
  }

  const result = margins.reduce<string[]>((acc, cur) => {
    if (typeof cur === 'string') {
      return [...acc, cur];
    }

    return [...acc, `${cur}px`];
  }, []);

  return `margin: ${result.join(' ')}`;
};

export const TypographyRoot = styled.span<{
  $align?: AlignKey;
  $color?: string;
  $weight?: FontWeight;
  $variant?: Variant;
  $margin?: number | Margins;
}>`
  text-align: ${({ $align }) => $align};
  color: ${({ $color }) => $color ?? Gray[900]};
  ${({ $margin }) => {
    if (!$margin) {
      return 'margin: 0';
    }

    return getMargins($margin);
  }};

  ${({ $variant }) => $variant && VariantMapping[$variant]}
  ${({ $weight }) => $weight && FontWeightMapping[$weight]}
`;
