import React, {
  useCallback,
  ChangeEvent,
  MouseEvent,
  FocusEventHandler,
  ReactNode,
  Ref,
  KeyboardEventHandler,
  CSSProperties,
} from 'react';
import FormItem from 'shared/ui/components/FormItem';
import type { InputRef } from 'antd';
import { EyeIcon } from '../../../assets';
import InputLabel from '../InputLabel';
import { StyledInput, StyledPasswordInput } from './styledComponents';
import { Brand } from '../../themes/colors';

enum LabelPosition {
  Top,
  Bottom,
}

export type InputProps = {
  onChange?: (value: string) => void;
  onBlur?: FocusEventHandler;
  id?: string;
  label?: ReactNode;
  error?: string;
  isPassword?: boolean;
  RightLabelComponent?: JSX.Element;
  disabled?: boolean;
  readOnly?: boolean;
  value?: string;
  placeholder?: string;
  labelPosition?: LabelPosition;
  bordered?: boolean;
  refs?: Ref<InputRef>;
  onKeyDown?: KeyboardEventHandler;
  color?: string;
  style?: CSSProperties;
  suffix?: React.ReactNode;
};

// TODO: revise the entire input logic, w/ <FormInput /> if we have a chance...

const Input = React.forwardRef<InputRef, InputProps>(
  (
    {
      id,
      label,
      isPassword,
      RightLabelComponent,
      onChange,
      error,
      disabled,
      value,
      placeholder,
      labelPosition,
      readOnly,
      suffix,
      // need this because to combine Field and Ant component
      // https://stackoverflow.com/a/45514030/5799742
      ...rest
    },
    ref,
  ) => {
    const onInputChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) =>
        onChange && onChange(e.target.value),
      [onChange],
    );

    const handleOnClick = useCallback(
      (e: MouseEvent<HTMLInputElement>) => {
        if (readOnly) {
          e.preventDefault();
          e.stopPropagation();
        }
      },
      [readOnly],
    );

    const iconRender = useCallback(
      (visible: boolean) => (
        <EyeIcon fill={visible ? Brand[700] : Brand[300]} />
      ),
      [],
    );

    const showLabelOnTop = !(
      labelPosition && labelPosition === LabelPosition.Bottom
    );

    const labelComponent = (
      <InputLabel label={label} RightLabelComponent={RightLabelComponent} />
    );

    return (
      <div>
        {showLabelOnTop && labelComponent}
        <FormItem
          validateStatus={error ? 'error' : ''}
          help={error || undefined}
        >
          {isPassword ? (
            <StyledPasswordInput
              {...rest}
              onChange={onInputChange}
              iconRender={iconRender}
              value={value}
              id={id}
            />
          ) : (
            <StyledInput
              {...rest}
              value={value}
              onMouseDown={handleOnClick}
              onChange={onInputChange}
              disabled={disabled}
              placeholder={placeholder}
              readOnly={readOnly}
              ref={ref}
              suffix={suffix}
              id={id}
            />
          )}
          {showLabelOnTop || labelComponent}
        </FormItem>
      </div>
    );
  },
);

export default React.memo(Input);
