import { Table } from 'antd';
import styled from 'styled-components';
import { Gray } from '../../themes/colors';

export const Container = styled.div`
  height: 100%;

  .ant-table-wrapper {
    width: 100%;
  }
`;

export const StyledTable = styled(Table)`
  .ant-table-thead {
    background-color: ${Gray[50]};

    .ant-table-cell {
      height: 44px;
    }

    & > tr > th {
      &:not(:last-child):not(.ant-table-selection-column) {
        content: none;
      }

      &:not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        content: none;
      }
    }
  }

  &.ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0;
  }

  &.ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0;
  }

  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-cell {
    height: 72px;
    padding: 12px 24px;
  }
`;
