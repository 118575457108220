import { ReactComponent as LightningIcon } from './illustrated/lightning.svg';
import { ReactComponent as StarIcon } from './illustrated/star.svg';
import { ReactComponent as HourGlassClockIcon } from './illustrated/hourglass_clock.svg';
import { ReactComponent as DollarGoldIcon } from './illustrated/dollar_gold.svg';
import { ReactComponent as MehFaceIcon } from './illustrated/meh_face.svg';
import { ReactComponent as AngryFaceIcon } from './illustrated/angry_face.svg';
import { ReactComponent as HappyFaceIcon } from './illustrated/happy_face.svg';
import { ReactComponent as FireSaleIcon } from './illustrated/fire_sale.svg';
import { ReactComponent as MedalIcon } from './illustrated/ic_medal.svg';
import { ReactComponent as PartyIcon } from './illustrated/ic_party.svg';
import { ReactComponent as SearchIcon } from './illustrated/ic_search.svg';
import { ReactComponent as ThinkOutOfTheBoxIcon } from './illustrated/think_out_of_the_box.svg';
import { ReactComponent as NetworkIcon } from './illustrated/network.svg';
import { ReactComponent as InteractionIcon } from './illustrated/interaction.svg';
import { ReactComponent as ComputerIcon } from './illustrated/computer.svg';
import { ReactComponent as DeleteAnchorIcon } from './illustrated/delete_anchor.svg';
import { ReactComponent as SpeedOneIcon } from './illustrated/speed_1.svg';
import { ReactComponent as SpeedTwoIcon } from './illustrated/speed_2.svg';
import { ReactComponent as StopwatchIcon } from './illustrated/stopwatch.svg';
import { ReactComponent as TypingErrorIcon } from './illustrated/typing_error.svg';
import { ReactComponent as TypingIcon } from './illustrated/typing.svg';
import { ReactComponent as RocketIcon } from './illustrated/ic_rocket.svg';
import { ReactComponent as RevenueIcon } from './illustrated/revenue.svg';
import { ReactComponent as MoneyBagIcon } from './illustrated/ic_money_bag.svg';
import { ReactComponent as StarIndigoIcon } from './illustrated/star_indigo.svg';
import { ReactComponent as FlameIcon } from './illustrated/ic_flame.svg';
import { ReactComponent as MagicWandIcon } from './illustrated/magic_wand.svg';

export const IllustratedIconTypes = {
  lightning: 'lightning',
  star: 'star',
  hourglassClock: 'hourglassClock',
  dollarGold: 'dollarGold',
  mehFace: 'mehFace',
  angryFace: 'angryFace',
  happyFace: 'happyFace',
  fireSale: 'fireSale',
  medal: 'medal',
  party: 'party',
  search: 'search',
  thinkOutOfTheBox: 'thinkOutOfTheBox',
  network: 'network',
  interaction: 'interaction',
  // typing test assets
  computer: 'computer',
  deleteAnchor: 'deleteAnchor',
  speedOne: 'speedOne',
  speedTwo: 'speedTwo',
  stopwatch: 'stopwatch',
  typingError: 'typingError',
  typing: 'typing',
  // end of typing test assets
  rocket: 'rocket',
  revenue: 'revenue',
  moneyBag: 'moneyBag',
  starIndigo: 'starIndigo',
  // coaching
  flame: 'flame',
  magicWand: 'magicWand',
} as const;

export type IllustratedIconType = keyof typeof IllustratedIconTypes;

export type IllustratedIconProps = {
  icon: IllustratedIconType;
  color?: string;
  stroke?: string;
  size?: number;
};

export const IllustratedIconComponentMaps = {
  [IllustratedIconTypes.lightning]: LightningIcon,
  [IllustratedIconTypes.star]: StarIcon,
  [IllustratedIconTypes.hourglassClock]: HourGlassClockIcon,
  [IllustratedIconTypes.dollarGold]: DollarGoldIcon,
  [IllustratedIconTypes.mehFace]: MehFaceIcon,
  [IllustratedIconTypes.angryFace]: AngryFaceIcon,
  [IllustratedIconTypes.happyFace]: HappyFaceIcon,
  [IllustratedIconTypes.fireSale]: FireSaleIcon,
  [IllustratedIconTypes.medal]: MedalIcon,
  [IllustratedIconTypes.party]: PartyIcon,
  [IllustratedIconTypes.search]: SearchIcon,
  [IllustratedIconTypes.thinkOutOfTheBox]: ThinkOutOfTheBoxIcon,
  [IllustratedIconTypes.network]: NetworkIcon,
  [IllustratedIconTypes.interaction]: InteractionIcon,
  [IllustratedIconTypes.computer]: ComputerIcon,
  [IllustratedIconTypes.deleteAnchor]: DeleteAnchorIcon,
  [IllustratedIconTypes.speedOne]: SpeedOneIcon,
  [IllustratedIconTypes.speedTwo]: SpeedTwoIcon,
  [IllustratedIconTypes.stopwatch]: StopwatchIcon,
  [IllustratedIconTypes.typingError]: TypingErrorIcon,
  [IllustratedIconTypes.typing]: TypingIcon,
  [IllustratedIconTypes.rocket]: RocketIcon,
  [IllustratedIconTypes.revenue]: RevenueIcon,
  [IllustratedIconTypes.moneyBag]: MoneyBagIcon,
  [IllustratedIconTypes.starIndigo]: StarIndigoIcon,
  [IllustratedIconTypes.flame]: FlameIcon,
  [IllustratedIconTypes.magicWand]: MagicWandIcon,
};
