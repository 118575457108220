const Extra = {
  1: '#C97DFF',
  2: '#24B2EF',
  goal: '#FFE3B2',
  subtext: '#97EEE5',
  dividers: '#DAE4EA',

  connector: '#294471',
  countryItemBorder: '#9B8AFB',
  starRating: '#FEC84B',
} as const;

export default Extra;
