export const BaseIconTypes = {
  arrowDropDown: 'arrowDropDown',
  arrowDown: 'arrowDown',
  btnClose: 'btnClose',
  arrowLeft: 'arrowLeft',
  arrowRight: 'arrowRight',
  moreHorizontal: 'moreHorizontal',
  tableEdit: 'tableEdit',
  tableTrash: 'tableTrash',
  trash: 'trash',
  approved: 'approved',
  pending: 'pending',
  training: 'training',
  applied: 'applied',
  removed: 'removed',
  union: 'union',
  checkSquare: 'checkSquare',
  reset: 'reset',
  edit: 'edit',
  cameraOff: 'cameraOff',
  add: 'add',
  plus: 'plus',
  subtract: 'subtract',
  multiply: 'multiply',
  divide: 'divide',
  helpCircle: 'helpCircle',
  calendar: 'calendar',
  clock: 'clock',
  user: 'user',
  buttonPrevious: 'buttonPrevious',
  info: 'info',
  star: 'star',
  loading: 'loading',
  copy: 'copy',
  chevronRight: 'chevronRight',
  help: 'help',
  play: 'play',
  angleDoubleRight: 'angleDoubleRight',
  noMonitor: 'noMonitor',
  caFlag: 'caFlag',
  usFlag: 'usFlag',
  phFlag: 'phFlag',
  mxFlag: 'mxFlag',
  alert: 'alert',
  userCheck: 'userCheck',
  scheduleCalendar: 'scheduleCalendar',
  slash: 'slash',
  zap: 'zap',
  medal: 'medal',
  party: 'party',
  search: 'search',
  happyFace: 'happyFace',
  mehFace: 'mehFace',
  angryFace: 'angryFace',
  hourglassClock: 'hourglassClock',
  starFilled: 'starFilled',
  rocket: 'rocket',
  meh: 'meh',
  frown: 'frown',
  smile: 'smile',
  phoneOff: 'phoneOff',
  flagMark: 'flagMark',
  streakRank: 'streakRank',
  mascot: 'mascot',
  fileBook: 'fileBook',
  fileCheck: 'fileCheck',
  fileDots: 'fileDots',
  fileX: 'fileX',
  laptop: 'laptop',
  file: 'file',
  briefCase: 'briefCase',
  award: 'award',
  folderCheck: 'folderCheck',
  tool: 'tool',
  refresh: 'refresh',
  loader: 'loader',
  check: 'check',
  download: 'download',
  upload: 'upload',
  fileDownload: 'fileDownload',
  dollar: 'dollar',
  coinStack: 'coinStack',
  send: 'send',
  dotsVertical: 'dotsVertical',
  globe: 'globe',
  building02: 'building02',
  folderMinus: 'folderMinus',
} as const;

export type BaseIconType = keyof typeof BaseIconTypes;
