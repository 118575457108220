import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import { getAuth } from 'firebase/auth';

import { firebaseConfig } from './firebaseConfig';

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();

firebase.analytics.isSupported().then((isSupported) => {
  if (isSupported) {
    firebase.analytics();
  }
});

db.settings({
  ignoreUndefinedProperties: true,
  merge: true,
});

export const dbNamespace = firebase.firestore;

// For now, we need to support the v9 (not compat) object as well since they come from different namespaces
export const auth2 = getAuth();

export const functions = firebase.functions();

export const authNamespace = firebase.auth;

export const auth = authNamespace();

export const storage = firebase.storage();
