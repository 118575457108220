const Neutral = {
  0: '#FFFFFF',
  10: '#F2F6F8',
  20: '#ECF1F5',
  30: '#F5FCFB',
  40: '#B8B8B8',
  60: '#5A6369',
  80: '#666666',
  100: '#1D1D41',
} as const;

export default Neutral;
