import { CSSProperties } from 'react';
import { Brand, Gradient, Gray, White, Error } from '../../themes/colors';
import shadows from '../../themes/shadows';
import {
  ButtonColor,
  ButtonColorType,
  ButtonVariant,
  ButtonVariantType,
} from './types';

type VariantColorConfig = CSSProperties & {
  hover: CSSProperties;
  focus: CSSProperties;
  disabled: CSSProperties;
  span?: CSSProperties;
};

type ColorsByVariant = Record<
  ButtonColorType,
  Record<ButtonVariantType, VariantColorConfig>
>;

const defaultBorderStyle = (color: string) => `1px solid ${color}`;

// https://www.figma.com/file/XBlYQK8vPfsP5saOOQyoGJ?node-id=1038:34411#332727154
// by default, we will apply opacity 0.3 for all disabled prop
export const colorsByVariant: ColorsByVariant = {
  [ButtonColor.BRAND]: {
    [ButtonVariant.CONTAINED]: {
      color: White,
      background: Gradient[700],
      hover: {
        background: Gradient[900],
      },
      focus: {
        boxShadow: `${shadows.xs}, 0px 0px 0px 4px ${Brand[100]}`,
      },
      disabled: {
        opacity: 0.3,
      },
    },
    [ButtonVariant.OUTLINED]: {
      color: Brand[500],
      background: White,
      border: defaultBorderStyle(Brand[500]),
      hover: {
        background: Brand[50],
      },
      focus: {
        boxShadow: `${shadows.xs}, 0px 0px 0px 4px ${Brand[100]}`,
      },
      disabled: {
        opacity: 0.3,
      },
      // see @https://caniuse.com/?search=webkit-text-fill-color
      span: {
        backgroundImage: Gradient.Primary[900],
        backgroundSize: '100%',
        backgroundRepeat: 'repeat',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      },
    },
    [ButtonVariant.LINK]: {
      boxShadow: 'none',
      color: Brand[500],
      hover: {
        color: Brand[600],
        textDecoration: 'underline',
      },
      focus: {
        textDecoration: 'underline',
      },
      disabled: {
        opacity: 0.3,
      },
    },
  },
  [ButtonColor.GRAY]: {
    [ButtonVariant.CONTAINED]: {
      color: White,
      background: Gray[500],
      hover: {
        background: Gray[900],
      },
      focus: {
        boxShadow: `${shadows.xs}, 0px 0px 0px 4px ${Gray[100]}`,
      },
      disabled: {
        opacity: 0.3,
      },
    },
    [ButtonVariant.OUTLINED]: {
      color: Gray[700],
      background: White,
      border: defaultBorderStyle(Gray[300]),
      hover: {
        color: Gray[800],
        background: Gray[50],
      },
      focus: {
        boxShadow: `${shadows.xs}, 0px 0px 0px 4px ${Gray[100]}`,
      },
      disabled: {
        opacity: 0.3,
      },
    },
    [ButtonVariant.LINK]: {
      boxShadow: 'none',
      color: Gray[700],
      hover: {
        color: Gray[900],
        textDecoration: 'underline',
      },
      focus: {
        textDecoration: 'underline',
      },
      disabled: {
        opacity: 0.3,
      },
    },
  },
  [ButtonColor.DESTRUCTIVE]: {
    [ButtonVariant.CONTAINED]: {
      color: White,
      background: Error[500],
      hover: {
        background: Error[600],
      },
      focus: {
        boxShadow: `${shadows.xs}, 0px 0px 0px 4px ${Error[100]}`,
      },
      disabled: {
        background: Error[200],
        opacity: 0.3,
      },
    },
    [ButtonVariant.OUTLINED]: {
      color: Error[700],
      background: White,
      border: defaultBorderStyle(Error[300]),
      hover: {
        color: Error[800],
        background: Error[50],
      },
      focus: {
        boxShadow: `${shadows.xs}, 0px 0px 0px 4px ${Error[100]}`,
      },
      disabled: {
        opacity: 0.3,
      },
    },
    [ButtonVariant.LINK]: {
      boxShadow: 'none',
      color: Error[700],
      hover: {
        color: Error[800],
        textDecoration: 'underline',
      },
      focus: {
        textDecoration: 'underline',
      },
      disabled: {
        opacity: 0.3,
      },
    },
  },
};
