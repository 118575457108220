import styled from 'styled-components';

import { DateFnsPicker as AntDatePicker } from 'shared/ui/components';

export const DatePicker = styled(AntDatePicker)`
  width: 100%;

  input {
    order: 2;
  }

  .ant-picker-suffix svg {
    width: 18px;
    margin-right: 8px;
    order: 2;
  }
`;
