const Agent = {
  25: '#F6FFFD',
  50: '#E9FEF9',
  100: '#C5FBED',
  200: '#6FE0C3',
  300: '#31C09A',
  400: '#00B081',
  500: '#009170',
  600: '#007460',
  700: '#00574F',
  800: '#00393F',
  900: '#001C2F',
} as const;

export default Agent;
