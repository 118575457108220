import { Select } from 'antd';
import styled from 'styled-components';
import { InputText } from 'shared/ui/components/Typography/styles';
import { baseColors } from 'shared/ui/theme';

export const StyledSelect: typeof Select = styled(Select)`
  width: 100%;

  .ant-select-selection-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${InputText}
    color:${baseColors.text};

    &::after {
      content: unset !important;
    }
  }

  .ant-select-selection-item {
    ${InputText}
  }
`;
