import {
  add,
  endOfWeek,
  startOfWeek,
  getMonth,
  getYear,
  format,
} from 'date-fns';

// 24 => 00:24,

function withLeadingZero(num: number) {
  return `${num > 9 ? num : `0${num}`}`;
}

type SecondsToHHMMSSStringOptions = {
  hideZeroHours?: boolean;
};

export function secondsToHHMMSSString(
  seconds: number,
  options: SecondsToHHMMSSStringOptions = {},
): string {
  const s = Math.floor(seconds % 60);
  const m = Math.floor((seconds / 60) % 60);
  const h = Math.floor(seconds / 60 / 60);

  return options.hideZeroHours === true && h === 0
    ? `${withLeadingZero(m)}:${withLeadingZero(s)}`
    : `${withLeadingZero(h)}:${withLeadingZero(m)}:${withLeadingZero(s)}`;
}

export function changeTimezone(date: Date, ianatz: string) {
  // suppose the date is 12:00 UTC
  const invdate = new Date(
    date.toLocaleString('en-US', {
      timeZone: ianatz,
    }),
  );

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  const diff = date.getTime() - invdate.getTime();

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff); // needs to substract
}

export const toUTC = (date: Date) => {
  const offset = date.getTimezoneOffset();
  return add(date, { minutes: offset });
};

export const formatWeekRange = (date: Date, includeYear = true) => {
  const startDate = startOfWeek(date);
  const endDate = endOfWeek(date);

  const startMonth = getMonth(startDate);
  const startYear = getYear(startDate);

  const endMonth = getMonth(endDate);
  const endYear = getYear(endDate);

  if (startYear !== endYear) {
    return `${format(startDate, 'LLL dd yyyy')} - ${format(
      endDate,
      'LLL dd yyyy',
    )}`;
  }
  const year = includeYear ? `/${startYear}` : '';
  if (startMonth !== endMonth) {
    return `${format(startDate, 'LLL dd')} - ${format(
      endDate,
      'LLL dd',
    )}${year}`;
  }
  return `${format(startDate, 'LLL dd')} - ${format(endDate, 'dd')}${year}`;
};
