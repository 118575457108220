import styled from 'styled-components';
import { baseColors } from 'shared/ui/theme';
import { Upload } from 'antd';

const { Dragger } = Upload;

export const StyledUpload = styled(Dragger)`
  &.ant-upload.ant-upload-drag {
    margin-bottom: 1rem;
    background-color: white;
    border: 2px dashed #dae4ea;

    .ant-upload-btn {
      padding: 0px;
    }

    .ant-upload-drag-container {
      padding: 12px 16px;
      text-align: start;
    }
  }

  .ant-upload-list {
    display: flex;

    .ant-upload-list-item {
      display: flex;
      gap: 16px;
      margin-right: 16px;
    }

    .ant-upload-list-item-name {
      display: none;
    }
  }
`;

export const StyledSubList = styled.ul`
  padding-left: 1rem;
  color: ${baseColors.secondaryText};
`;
