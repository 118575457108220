import React, { useCallback, ChangeEvent, FocusEventHandler } from 'react';
import { TextAreaRef } from 'antd/es/input/TextArea';
import InputLabel from '../InputLabel';
import { StyledTextArea, FormItem } from './styledComponents';

export type TextAreaProps = {
  onChange: (value: string) => void;
  label: React.ReactNode;
  error?: string;
  RightLabelComponent?: JSX.Element;
  disabled?: boolean;
  value?: string;
  placeholder?: string;
  rows?: number;
  onBlur?: FocusEventHandler;
  className?: string;
  autoSize?: { minRows?: number; maxRows?: number };
};

const TextAreaInput = React.forwardRef<TextAreaRef, TextAreaProps>(
  (
    {
      label,
      RightLabelComponent,
      onChange,
      error,
      disabled,
      value,
      placeholder,
      rows,
      onBlur,
      // need this because to combine Field and Ant component
      // https://stackoverflow.com/a/45514030/5799742
      ...rest
    },
    ref,
  ) => {
    const onInputChange = useCallback(
      (e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value),
      [onChange],
    );

    return (
      <div>
        <InputLabel label={label} RightLabelComponent={RightLabelComponent} />
        <FormItem
          validateStatus={error ? 'error' : ''}
          help={error || undefined}
        >
          <StyledTextArea
            ref={ref}
            value={value}
            onChange={onInputChange}
            onBlur={onBlur}
            disabled={disabled}
            placeholder={placeholder}
            rows={rows || 5}
            {...rest}
          />
        </FormItem>
      </div>
    );
  },
);

export default React.memo(TextAreaInput);
