import { CSSProperties } from 'react';
import { Buttons, ButtonType, ButtonVariant, ButtonVariantType } from './types';
import { Button, Neutral, Secondary } from '../../colors';
import Gradient from '../../colors/gradient';
import shadows from '../../shadows';

type VariantColorConfig = CSSProperties & {
  hover: CSSProperties;
  focus: CSSProperties;
  disabled: CSSProperties;
  span?: CSSProperties;
};

type ColorsByVariant = Record<
  ButtonType,
  Record<ButtonVariantType, VariantColorConfig>
>;

const defaultBorderStyle = (color: string) => `2px solid ${color}`;

// TODO: type checking when developer trying to do primary + link combination
// see @https://www.figma.com/file/vdOVQsSBnmL7LIAAX6FOD2/AO-Design-System---Client?node-id=253%3A122&t=uBU7P9wcQy3GKcCn-0
export const colorsByVariant: ColorsByVariant = {
  [Buttons.DEFAULT]: {
    [ButtonVariant.CONTAINED]: {
      color: Secondary.main,
      background: Neutral[0],
      border: defaultBorderStyle('#8E949C'), // TODO: add it into theme color
      hover: {
        opacity: 0.8,
        background: Neutral[0],
      },
      focus: {
        background: Neutral[0],
      },
      disabled: {
        opacity: 0.15,
      },
    },
    [ButtonVariant.OUTLINED]: {
      color: Secondary.main,
      background: Neutral[0],
      border: defaultBorderStyle('#8E949C'), // TODO: add it into theme color
      hover: {
        opacity: 0.8,
      },
      focus: {
        background: Neutral[0],
      },
      disabled: {
        opacity: 0.15,
      },
    },
    [ButtonVariant.LINK]: {
      boxShadow: 'none',
      border: defaultBorderStyle('transparent'),
      color: Secondary.main,
      background: 'transparent',
      hover: {
        color: Button.link.hover,
      },
      focus: {
        color: Button.link.focus,
      },
      disabled: {
        opacity: 0.25,
      },
    },
  },
  [Buttons.BRAND]: {
    [ButtonVariant.CONTAINED]: {
      color: Neutral[0],
      background: Button.primary.default,
      border: defaultBorderStyle('transparent'),
      hover: {
        background: Button.primary.hover,
      },
      focus: {
        background: Button.primary.focus,
      },
      disabled: {
        opacity: 0.15,
      },
    },
    [ButtonVariant.OUTLINED]: {
      color: Button.primary.default,
      background: Neutral[0],
      border: defaultBorderStyle(Button.primary.default),
      hover: {
        background: Neutral[0],
        border: defaultBorderStyle(Button.primary.hover),
        color: Button.primary.hover,
      },
      focus: {
        background: Neutral[0],
        border: defaultBorderStyle(Button.primary.focus),
        color: Button.primary.focus,
      },
      disabled: {
        background: Neutral[0],
        opacity: 0.15,
      },
    },
    [ButtonVariant.LINK]: {
      boxShadow: 'none',
      border: defaultBorderStyle('transparent'),
      color: Secondary.main,
      background: 'transparent',
      hover: {
        color: Button.link.hover,
      },
      focus: {
        color: Button.link.focus,
      },
      disabled: {
        opacity: 0.25,
      },
    },
  },
  [Buttons.ALERT]: {
    [ButtonVariant.CONTAINED]: {
      color: Neutral[0],
      background: Button.alert.default,
      border: defaultBorderStyle('transparent'),
      hover: {
        background: Button.alert.hover,
      },
      focus: {
        background: Button.alert.focus,
      },
      disabled: {
        opacity: 0.15,
      },
    },
    [ButtonVariant.OUTLINED]: {
      color: Button.alert.default,
      background: Neutral[0],
      border: defaultBorderStyle(Button.alert.default),
      hover: {
        background: Neutral[0],
        border: defaultBorderStyle(Button.alert.hover),
        color: Button.alert.hover,
      },
      focus: {
        background: Neutral[0],
        border: defaultBorderStyle(Button.alert.focus),
        color: Button.alert.focus,
      },
      disabled: {
        background: Neutral[0],
        opacity: 0.15,
      },
    },
    [ButtonVariant.LINK]: {
      boxShadow: 'none',
      border: defaultBorderStyle('transparent'),
      color: Button.alert.default,
      background: 'transparent',
      hover: {
        color: Button.alert.hover,
      },
      focus: {
        color: Button.alert.focus,
      },
      disabled: {
        opacity: 0.25,
      },
    },
  },
  [Buttons.GRADIENT]: {
    [ButtonVariant.CONTAINED]: {
      color: Neutral[0],
      background: Gradient[700],
      hover: {
        background: Gradient[900],
      },
      focus: {
        boxShadow: `${shadows.subtle}, 0px 0px 0px 4px ${Gradient.Solid.Green[100]}`,
      },
      disabled: {
        opacity: 0.3,
      },
    },
    [ButtonVariant.OUTLINED]: {
      color: Gradient.Solid.Green[500],
      background: Neutral[0],
      border: defaultBorderStyle(Gradient.Solid.Green[500]),
      hover: {
        background: Gradient.Solid.Green[50],
      },
      focus: {
        boxShadow: `${shadows.subtle}, 0px 0px 0px 4px ${Gradient.Solid.Green[100]}`,
      },
      disabled: {
        opacity: 0.3,
      },
      // see @https://caniuse.com/?search=webkit-text-fill-color
      span: {
        backgroundImage: Gradient.Primary[900],
        backgroundSize: '100%',
        backgroundRepeat: 'repeat',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      },
    },
    [ButtonVariant.LINK]: {
      color: Gradient.Solid.Green[500],
      hover: {
        color: Gradient.Solid.Green[600],
        textDecoration: 'underline',
      },
      focus: {
        textDecoration: 'underline',
      },
      disabled: {
        opacity: 0.3,
      },
    },
  },
  [Buttons.MAI]: {
    [ButtonVariant.CONTAINED]: {
      color: Neutral[0],
      background: Gradient.Graphic[4],
      hover: {
        background: Gradient.Graphic[5],
      },
      focus: {
        boxShadow: `${shadows.subtle}, 0px 0px 0px 4px ${Gradient.Solid.Green[100]}`,
      },
      disabled: {
        opacity: 0.3,
      },
    },
    [ButtonVariant.OUTLINED]: {
      color: Gradient.Graphic[4],
      background: Neutral[0],
      border: defaultBorderStyle(Gradient.Solid.Indigo[500]),
      hover: {
        background: Gradient.Solid.Indigo[50],
      },
      focus: {
        boxShadow: `${shadows.subtle}, 0px 0px 0px 4px ${Gradient.Solid.Green[100]}`,
      },
      disabled: {
        opacity: 0.3,
      },
      // see @https://caniuse.com/?search=webkit-text-fill-color
      span: {
        backgroundImage: Gradient.Graphic[4],
        backgroundSize: '100%',
        backgroundRepeat: 'repeat',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      },
    },
    [ButtonVariant.LINK]: {
      color: Gradient.Graphic[4],
      boxShadow: 'none',
      hover: {
        color: Gradient.Graphic[5],
        textDecoration: 'underline',
      },
      focus: {
        textDecoration: 'underline',
      },
      disabled: {
        opacity: 0.3,
      },
      // see @https://caniuse.com/?search=webkit-text-fill-color
      span: {
        backgroundImage: Gradient.Graphic[4],
        backgroundSize: '100%',
        backgroundRepeat: 'repeat',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      },
    },
  },
};
