import styled from 'styled-components';
import { Neutral } from 'client-web/themes/v2';

const Schedule = styled.div`
  background-color: ${Neutral[0]};
  padding: 32px;
  width: 100%;
`;

export { Schedule };
