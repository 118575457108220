import styled from 'styled-components';
import { Input } from 'antd';
import { baseColors } from '../../theme';

export const StyledInput = styled(Input)`
  border: ${(props) =>
    props.bordered !== false ? `1px solid ${baseColors.dividers}` : 'none'};
  border-radius: 4px;
`;

export const StyledPasswordInput: typeof Input.Password = styled(
  Input.Password,
)`
  height: 48px;
`;

export const PasswordVisibilityHiddenButton = styled.button`
  position: absolute;
  right: 11px;
  top: calc((100% - 18px) / 2);
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
