import { ExternalValueType, ISOCountryKeys } from '@agentsonly/models';

export const NUMBER_REGEX = /^-?\d*(\.\d*)?$/;

export const FILE_TYPES = {
  pdf: 'pdf',
  mp4: 'mp4',
  pptx: 'pptx',
  doc: 'doc',
  docx: 'docx',
};

export const MIME_TYPES: { [key: string]: string } = {
  pdf: 'application/pdf',
  mp4: 'application/mp4',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

export const SUGGESTED_FILE_TYPES = [
  FILE_TYPES.pdf,
  FILE_TYPES.doc,
  FILE_TYPES.docx,
];

export const BIT_RATE = 128;
export const DEFAULT_VOICE_FILENAME = 'onboarding.mp3';

export const US_DATE_FORMAT = 'MM/dd/yy';
export const COACHING_BADGE_FORMAT = 'MMM dd, yyyy';
export const COACHING_COMING_DATE = 'MM/dd/yyyy';
export const CLIENT_INVOICE_DATE = 'MM/dd/yyyy';
export const TIME_FORMAT = 'hh:mm a';
export const FULL_DATE_FORMAT = 'yyyy/MM/dd';
export const HRS_MINS_SECS_FORMAT = 'hh:mm:ss';
export const FULL_DATE_HRS_MINS_SECS_FORMAT = 'yyyy/MM/dd HH:mm:ss';
export const YEAR_WEEK_FORMAT = 'YYYYww';
export const WEEK_DAY = 'E';
export const COMEBACK_DATE = 'LLLL dd';
export const SURGE_PAY_DAY_FORMAT = 'MMMM dd, EEEE, H:mm';
export const SURGE_PAY_TIME_FORMAT = 'H:mm';
export const HOUR_24_MINUTE_FORMAT = 'H:mm';
export const CURRENT_SCHEDULE_FORMAT = 'MMM, yyyy';
export const HOUR_MINUTE_FORMAT = 'HHmm';
export const FALL_OF_CONSTANTINOPLE_DATE_FORMAT = 'PP';
export const WEEK_DAY_MONTH_DAY = 'eeee dd';

export const CHAT_DATE_TIME_FORMAT = 'do MMM, h:mm a';
export const CHAT_AI_DATE_FORMAT = 'MMM d, yyyy';

export function isDev(): boolean {
  return process.env.REACT_APP_ENV === 'dev';
}

export function isStage(): boolean {
  return process.env.REACT_APP_ENV === 'stage';
}

export function isProd(): boolean {
  return process.env.REACT_APP_ENV === 'prod';
}

export function isLocalhost(): boolean {
  const LOCAL_DOMAINS = [
    'localhost',
    // IPv4
    '127.0.0.1',
    // IPv6
    '[::1]',
    '',
  ];

  return LOCAL_DOMAINS.includes(window.location.hostname);
}

/** determine current portal "client" or "agent" */
export const getCurrentPortal = () =>
  process.env.REACT_APP_BUILD_TARGET?.trim();

export function isClientPortal() {
  return getCurrentPortal() === 'client';
}

export const TMP_FIELD = { tmp: 'tmp' };

export const SCHEDULE_STEP = 30; // minutes

export const COUNTS_OF_DAY_PER_30MINS = 48;

export const COUNTS_OF_DAY_PER_15MINS = 96;

export const DAYS_IN_WEEK = 7;

export const PROJECT_TZ = 'America/Vancouver';

export const AsyncState = {
  Idle: 'Idle',
  Pending: 'Pending',
  Fulfilled: 'Fulfilled',
  Rejected: 'Rejected',
} as const;
export type AsyncStateType = (typeof AsyncState)[keyof typeof AsyncState];

export const REACT_APP_AUTH0_DOMAIN = (() => {
  const val = isProd()
    ? process.env.REACT_APP_AUTH0_DOMAIN_PROD
    : isStage()
    ? process.env.REACT_APP_AUTH0_DOMAIN_STAGE
    : process.env.REACT_APP_AUTH0_DOMAIN_DEV;
  return val ?? '';
})();

export const REACT_APP_AUTH0_CLIENT_ID = (() => {
  const val = isProd()
    ? process.env.REACT_APP_AUTH0_CLIENT_ID_PROD
    : isStage()
    ? process.env.REACT_APP_AUTH0_CLIENT_ID_STAGE
    : process.env.REACT_APP_AUTH0_CLIENT_ID_DEV;
  return val ?? '';
})();

export const REACT_APP_AUTH0_API_AUDIENCE = (() => {
  const val = isProd()
    ? process.env.REACT_APP_AUTH0_API_AUDIENCE_PROD
    : isStage()
    ? process.env.REACT_APP_AUTH0_API_AUDIENCE_STAGE
    : process.env.REACT_APP_AUTH0_API_AUDIENCE_DEV;
  return val ?? '';
})();

// Hardcoded to the only test entry for now
// TODO: will need to change for prod
export const AGENT_QUIZ_ID = isDev()
  ? 'nzcjb3vjcabO7zmfPYHY'
  : 'ERzLdw93g6O9pjVzNslB';

export const TYPING_AVG_SCORE = 35;
export const QUIZ_PASSED_SCORE = 70;

export const EXCLUDE_KEYS_FROM_BACKGROUND_CHECK = [
  'allPassed',
  'reviewStatus',
  'status',
];

export enum OperatorSign {
  Equal = '==',
  NotEqual = '!=',
  Greater = '>',
  GreaterOrEqual = '>=',
  Less = '<',
  LessOrEqual = '<=',
}

type DimensionOptionData = {
  stringValues?: string[];
  valueType?: ExternalValueType;
};

export type DimensionOption = {
  value: string;
  label: string;
  data: DimensionOptionData;
};

export type Dimension = {
  label: string;
  value: string;
  unit?: 'percent' | 'dollar' | 'none';
};

export enum BonusType {
  Plus = 'plus',
  Minus = 'minus',
}

export type Operator = {
  sign: OperatorSign;
  description: string;
};

export const OPERATORS: Operator[] = [
  { sign: OperatorSign.Equal, description: 'starAlgorithm.operator.equal' },
  {
    sign: OperatorSign.NotEqual,
    description: 'starAlgorithm.operator.notequal',
  },
  { sign: OperatorSign.Greater, description: 'starAlgorithm.operator.greater' },
  {
    sign: OperatorSign.GreaterOrEqual,
    description: 'starAlgorithm.operator.greater_or_equal',
  },
  { sign: OperatorSign.Less, description: 'starAlgorithm.operator.less' },
  {
    sign: OperatorSign.LessOrEqual,
    description: 'starAlgorithm.operator.less_or_equal',
  },
];

export const DEFAULT_EMPTY_VALUE = '--';
export const DEFAULT_NOT_AVAILABLE = 'N/A';

export const AO_ASSET_URL = 'https://assets.agentsonly.com';

export const HIDDEN_STATUS = 'hidden';

export const SUPPORT_EMAIL_ADDRESS = 'support@agentsonly.com';

export const REFERRER_KEY = 'referrer';

export const CALCULATED_METRICS_PREFIX = 'calcMetric:';
/**
 * Represents the loading status of a resource.
 * It can be one of the following values:
 * - 'complete': Indicates that the resource has finished loading.
 * - 'loading': Indicates that the resource is currently being loaded.
 * - 'rejected': Indicates that the loading of the resource has been rejected.
 * - null: Indicates that the relative resource is not initialized yet.
 */
export type LoadingStatus = 'complete' | 'loading' | 'rejected' | null;
export const LoadingStatus = {
  Complete: 'complete' as LoadingStatus,
  Loading: 'loading' as LoadingStatus,
  Rejected: 'rejected' as LoadingStatus,
  Idle: null as LoadingStatus,
};

export const DEFAULT_PAGE_SIZE = 10;

// Legal documents version
export const LATEST_CONTRACTOR_AGREEMENT_VERSION = 2;

export const DEFAULT_COUNTRY_CODE = ISOCountryKeys.CA;
