import React, { FocusEventHandler, ReactNode } from 'react';
import { startOfDay } from 'date-fns';

import { baseColors } from 'shared/ui/theme';
import { ReactComponent as CalenderIcon } from './assets/calender.svg';
import InputLabel from '../InputLabel';
import FormItem from '../FormItem';

import { DatePicker } from './styledComponents';

const disabledDate = (current: Date) =>
  current && current < startOfDay(new Date());

type Props = {
  error?: string;
  label: string | ReactNode;
  onChange: (value: Date | null) => void;
  value?: string;
  disablePast?: boolean;
  disabled?: boolean;
  onBlur?: FocusEventHandler;
  format?: string;
  dateTestId?: string;
  name?: string;
};

const SingleDatePicker: React.FC<Props> = ({
  label,
  onChange,
  error,
  value,
  disablePast,
  disabled,
  onBlur,
  format = 'yyyy-MM-dd',
  dateTestId,
  name,
}) => {
  return (
    <div>
      <InputLabel label={label} htmlFor={name} />
      <FormItem validateStatus={error ? 'error' : ''} help={error || undefined}>
        <DatePicker
          id={name}
          onBlur={onBlur}
          value={value ? new Date(value) : undefined}
          disabledDate={disablePast ? disabledDate : undefined}
          onChange={onChange}
          suffixIcon={<CalenderIcon color={baseColors.neutral40} />}
          disabled={disabled}
          format={format}
          data-testid={dateTestId}
          name={name}
        />
      </FormItem>
    </div>
  );
};

export default React.memo(SingleDatePicker);
