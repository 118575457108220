export interface DebugData {
  build: Record<string, string>;
}

export const DEBUG_DATA: DebugData = {
  build: {
    PR_NUMBER: process.env.REACT_APP_PR_NUMBER || '',
    COMMIT_ID: process.env.REACT_APP_COMMIT_ID || '',
  },
};
