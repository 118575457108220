import React from 'react';

type Props = {
  id?: string;
  className?: string;
  dataTestId?: string;
  condition: boolean;
  wrapper: (children: React.ReactNode) => React.ReactNode;
  children: React.ReactNode;
};

/** If `condition` is satisfied, wrap `children` with `wrapper` */
export const ConditionalWrapper: React.FC<Props> = ({
  id,
  className,
  dataTestId,
  condition,
  wrapper,
  children,
}) => (
  <div id={id} data-testid={dataTestId} className={className}>
    {condition ? wrapper(children) : children}
  </div>
);
