import { colorsByVariant } from './config';
import { ButtonType, ButtonVariantType, Scales, ScaleType } from './types';

export const handleScale = (scale: ScaleType, padding?: string) => {
  switch (scale) {
    case Scales.small:
      return {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '18px',
        padding: padding || '11px 20px',
        height: '40px',
      };
    case Scales.regular:
      return {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '21px',
        padding: padding || '13.5px 32px',
        height: '48px',
      };
    // default regular
    default:
      return {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '21px',
        padding: padding || '13.5px 32px',
        height: '48px',
      };
  }
};

export const getVariantColorProps = (
  variant: ButtonVariantType,
  buttonType: ButtonType,
) => {
  const { color, background, border, focus, hover, disabled, ...rest } =
    colorsByVariant[buttonType][variant];

  return {
    ...rest,
    color,
    background: background ?? 'transparent',
    border: border ?? 'none',
    hover: {
      ...hover,
      color: hover.color ?? color,
      background: hover.background ?? 'transparent',
      border: hover.border ?? border ?? 'none',
    },
    focus: {
      ...focus,
      color: focus.color ?? color,
      background: focus.background ?? background ?? 'transparent',
      border: focus.border ?? border ?? 'none',
    },
    disabled: {
      ...disabled,
      color: disabled.color ?? color,
      background: disabled.background ?? background ?? 'transparent',
      border: disabled.border ?? border ?? 'none',
    },
  };
};
