import { Secondary } from 'client-web/themes/v2/colors';
import React from 'react';
import { Typography } from 'shared/ui/nuba';
import { PagerContainer } from './styledComponents';

type PaginationPagerProps = {
  pageNum: number;
};

const PaginationPager: React.FC<PaginationPagerProps> = ({ pageNum }) => (
  <PagerContainer>
    <Typography color={Secondary.medium[2]} variant="textSm">
      {pageNum}
    </Typography>
  </PagerContainer>
);

export default React.memo(PaginationPager);
