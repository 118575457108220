import { isStage, isProd } from '../utils/consts';

export const WalletApiPath = {
  SUBMIT_WITHDRAW: '/wallet/txs/withdraw',
  GET_WITHDRAW_CONFIG: '/wallet/countryConfig',
  GET_IS_FEE_REQUIRED: '/wallet/txs/isfeerequired',
  GET_WALLET: '/wallet',
};

export const API_BASE_URL = (() => {
  return isProd()
    ? 'https://api.agentsonly.com'
    : isStage()
    ? 'https://api-stage.agentsonly.com'
    : 'https://api-dev.agentsonly.com';
})();

// https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net
export const FIREBASE_FUNCTIONS_BASE_URL = (() => {
  return isProd()
    ? 'https://us-central1-agents-only.cloudfunctions.net'
    : isStage()
    ? 'https://us-central1-agents-only-stage.cloudfunctions.net'
    : 'https://us-central1-agents-only-dev.cloudfunctions.net';
})();
