import styled from 'styled-components';

import { Form } from 'antd';

const FormItem = styled(Form.Item)`
  &:not(.ant-form-item-with-help) {
    margin-bottom: 16px;
  }
  .ant-radio-group {
    margin-top: 16px;
  }
`;

export default FormItem;
