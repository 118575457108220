import React from 'react';

import { QuestionMarkIcon } from 'shared/ui/assets';
import { baseColors } from 'shared/ui/theme';
import Popover from '../Popover';

import { IconContainer } from './styledComponents';

type Props = {
  text: string;
  iconColor?: string;
};

const FormPopover: React.FC<Props> = ({ text, iconColor }) => (
  <IconContainer>
    <Popover content={text}>
      <QuestionMarkIcon fill={iconColor || baseColors.primaryButton} />
    </Popover>
  </IconContainer>
);

export default React.memo(FormPopover);
