import styled from 'styled-components';
import { Modal as AntModal } from 'antd';
import { Gray } from '../../themes/colors';
import shadows from '../../themes/shadows';

export const StyledModal = styled(AntModal)`
  .ant-modal-header {
    border: none;
    border-radius: inherit;
    padding: 24px;
  }

  .ant-modal-footer {
    border-top: 1px solid ${Gray[200]};
    padding: 13px 0 24px 0;
    margin: 0 24px;
  }

  .ant-modal-content {
    border-radius: 20px;
    box-shadow: ${shadows.modal.xl};
  }
`;
