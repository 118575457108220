import React from 'react';
import {
  GreenCheckIcon,
  RoundErrorIcon,
  AnnouncementIcon,
} from 'shared/ui/assets';
import { notification } from 'shared/utils/AntdHelpers';
import { getErrorMessage } from 'shared/utils/error';

export enum IconType {
  Success = 'success',
  Error = 'error',
  Announcement = 'announcement',
}

const getIcon = (type: IconType): JSX.Element | undefined => {
  switch (type) {
    case IconType.Success:
      return <GreenCheckIcon />;
    case IconType.Error:
      return <RoundErrorIcon />;
    case IconType.Announcement:
      return <AnnouncementIcon />;
    default:
      return undefined;
  }
};

// TODO: make it object args to extensible to have more args
export const showNotification = (
  message: string,
  icon: IconType | undefined,

  style?: React.CSSProperties,
  onClose?: () => void,
): void => {
  notification.open({
    key: message, // FIXME: can pass key easily when args convert to object
    message,
    duration: 5,
    placement: 'bottomLeft',
    icon: icon && getIcon(icon),
    style,
    onClose,
  });
};

/**
 * show Error Notification
 * @param messageOrError accept message string or Error object (try to get Error.message and display)
 */
// TODO: make it object args to extensible to have more args
export const showNotificationError = (
  messageOrError: string | unknown,

  style?: React.CSSProperties,
  onClose?: () => void,
): void => {
  const errorMessage =
    typeof messageOrError === 'string'
      ? messageOrError
      : getErrorMessage(messageOrError);

  console.error(errorMessage);
  showNotification(errorMessage, IconType.Error, style, onClose);
};
