import React from 'react';
import { ButtonProps as BaseButtonProps } from 'antd/lib/button';
import { CircleStyle, SquareStyle } from './styledComponents';
import {
  ButtonShape,
  Scales,
  ScaleType,
  ButtonShapeType,
  ButtonVariantType,
  ButtonVariant,
  ButtonType,
  Buttons,
} from './types';
import { getVariantColorProps } from './utils';

/**
 * TODO: Design System (Ref: https://www.figma.com/file/vdOVQsSBnmL7LIAAX6FOD2/AO-Design-System---Client?node-id=253%3A122&t=Fpd5tH7D1l60rzlR-0)
 */
type Props = BaseButtonProps & {
  buttonType?: ButtonType;
  scale?: ScaleType;
  minWidth?: number | string;
  padding?: string;
  buttonShape?: ButtonShapeType;
  className?: string;
  variant?: ButtonVariantType;
};

const ButtonComponentMaps = {
  [ButtonShape.CIRCLE]: CircleStyle,
  [ButtonShape.SQUARE]: SquareStyle,
};

// TODO: rename to Button after refactored
const V2Button: React.FC<Props> = ({
  buttonType = Buttons.BRAND,
  scale = Scales.regular,
  minWidth,
  padding,
  buttonShape = ButtonShape.SQUARE,
  variant = ButtonVariant.CONTAINED,
  className,
  ...props
}) => {
  const ButtonComponent = ButtonComponentMaps[buttonShape];
  const { focus, hover, disabled, ...baseProps } = getVariantColorProps(
    variant,
    buttonType,
  );

  return (
    <ButtonComponent
      className={className}
      $scale={scale}
      $minWidth={minWidth}
      $padding={padding}
      $disabledProps={disabled}
      $focusProps={focus}
      $hoverProps={hover}
      $baseProps={baseProps}
      {...props}
    />
  );
};

export default V2Button;
