import styled from 'styled-components';

type axisType = 'horizontal' | 'vertical';

type Props = {
  axis: axisType;
  size: number;
};

const getHeight = ({ axis, size }: Props) => (axis === 'horizontal' ? 1 : size);
const getWidth = ({ axis, size }: Props) => (axis === 'vertical' ? 1 : size);

const Spacer = styled.span`
  display: block;
  width: ${getWidth}px;
  min-width: ${getWidth}px;
  height: ${getHeight}px;
  min-height: ${getHeight}px;
`;

export default Spacer;
