/**
 * The function `hexToRGB` converts a hexadecimal color code to its corresponding RGB or RGBA
 * representation.
 * @example
 * hexRGB('#078768') // alpha 100%, return rgb(7, 135, 104)
 * hexRGB('#078768', 0.5) // alpha 50%, return rgba(7, 135, 104, 0.5)
 * @param {string} hex - The `hex` parameter is a string representing a hexadecimal color value. It
 * should be in the format `#RRGGBB`, where RR represents the red component, GG represents the green
 * component, and BB represents the blue component.
 * @param {number} [alpha] - The `alpha` parameter is an optional parameter that represents the opacity
 * of the color. It is a number between 0 and 1, where 0 represents fully transparent and 1 represents
 * fully opaque. If the `alpha` parameter is provided, the function will return the color in the RGBA
 * format
 * @returns The function `hexToRGB` returns a string representation of the RGB or RGBA color value,
 * depending on whether the `alpha` parameter is provided or not.
 */
export const hexToRGB = (hex: string, alpha?: number): string => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
};
