import React from 'react';
import { ModalProps } from 'antd/lib/modal';
import { StyledModal } from './styledComponents';
import { Gray } from '../../themes/colors';
import { BaseIcon } from '../../assets/icons';

const MODAL_DEFAULT_WIDTH = 400;

type Props = {
  children: React.ReactNode | React.ReactNode[] | null;
  width?: string | number;
};

const Modal: React.FC<ModalProps & Props> = ({ children, width, ...props }) => {
  return (
    <StyledModal
      {...props}
      width={width || MODAL_DEFAULT_WIDTH}
      closeIcon={<BaseIcon icon="xCircle" stroke={Gray[400]} size={24} />}
    >
      {children}
    </StyledModal>
  );
};

export default React.memo(Modal);
