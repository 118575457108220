import styled from 'styled-components';
import { Input, Form } from 'antd';
import { baseColors } from '../../theme';

const { TextArea } = Input;

export const FormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;

export const StyledTextArea = styled(TextArea)`
  border: 1px solid ${baseColors.dividers};
  border-radius: 4px;
`;
