import styled from 'styled-components';
import { Gray } from '../../themes/colors';

export const StyledTable = styled.table<{ $isLoading?: boolean }>`
  width: 100%;
  opacity: ${({ $isLoading }) => ($isLoading ? 0.5 : 1)};

  thead th {
    background: ${Gray[50]};
    border-top: 1px solid ${Gray[200]};
    border-bottom: 1px solid ${Gray[200]};
    align-items: center;
    height: 44px;
    text-align: left;
  }

  tbody {
    tr {
      height: 64px;
    }

    td,
    th {
      border-bottom: 1px solid ${Gray[200]};
    }
  }
`;
