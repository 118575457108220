import { FC, memo } from 'react';
import {
  FixedHeaderContent,
  ItemContent,
  TableVirtuoso,
  TableVirtuosoProps,
} from 'react-virtuoso';
import { StyledTable } from './styledComponents';

// D for Data, C for Context
type Props<D, C> = TableVirtuosoProps<D, C> & {
  data: D[];
  height?: number | string;
  fixedHeaderContent?: FixedHeaderContent;
  itemContent?: ItemContent<D, C>;
  isLoading?: boolean;
};

const InfiniteScrollTable: FC<Props<any, any>> = memo((baseProps) => {
  const {
    data,
    height = '100%',
    fixedHeaderContent,
    itemContent,
    isLoading,
    ...virtuosoProps
  } = baseProps;

  return (
    <TableVirtuoso
      style={{ height }}
      data={data}
      components={{
        Table: ({ style, ...props }) => (
          <StyledTable {...props} style={{ ...style }} $isLoading={isLoading} />
        ),
      }}
      fixedHeaderContent={fixedHeaderContent}
      itemContent={itemContent}
      className="agent-virtual-table"
      {...virtuosoProps}
    />
  );
});

export default InfiniteScrollTable;
