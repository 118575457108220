import { css } from 'styled-components';
import { lineHeight, rem } from 'shared/utils/styles';

// Title Style
export const DisplayXxl = css`
  font-size: ${rem(72)};
  line-height: ${lineHeight(93.74, 72)};
`;

export const DisplayXl = css`
  font-size: ${rem(60)};
  line-height: ${lineHeight(78.12, 60)};
`;

export const DisplayLg = css`
  font-size: ${rem(48)};
  line-height: ${lineHeight(62.5, 48)};
`;

export const DisplayMd = css`
  font-size: ${rem(36)};
  line-height: ${lineHeight(46.87, 36)};
`;

export const DisplaySm = css`
  font-size: ${rem(30)};
  line-height: ${lineHeight(39.06, 30)};
`;

export const DisplayXs = css`
  font-size: ${rem(24)};
  line-height: ${lineHeight(31.25, 24)};
`;

// Text Style
export const TextXl = css`
  font-size: ${rem(20)};
  line-height: ${lineHeight(26.04, 20)};
`;

export const TextLg = css`
  font-size: ${rem(18)};
  line-height: ${lineHeight(23.44, 18)};
`;

export const TextMd = css`
  font-size: ${rem(16)};
  line-height: ${lineHeight(20.83, 16)};
`;

export const TextSm = css`
  font-size: ${rem(14)};
  line-height: ${lineHeight(20, 14)};
`;

export const TextXs = css`
  font-size: ${rem(12)};
  line-height: ${lineHeight(15.62, 12)};
`;

export const TextXxs = css`
  font-size: ${rem(10)};
  line-height: ${lineHeight(12, 10)};
`;

// Font Weight
export const NUBA_FONT = {
  regular: 400,
  medium: 600,
  bold: 800,
};

export const Regular = css`
  font-weight: ${NUBA_FONT.regular};
`;

export const Medium = css`
  font-weight: ${NUBA_FONT.medium};
`;

export const Bold = css`
  font-weight: ${NUBA_FONT.bold};
`;
