import { isClientPortal, isDev } from 'shared/utils/consts';

interface TrackingConfig {
  amplitudeApiKey: string;
  amplitudeSecretKey: string;
  sentryDSN: string;
  hotjarID?: number;
}

const isClient = isClientPortal();

const prod = {
  agent: {
    amplitudeApiKey: '1741788f91a8c01675112a84b298b82a',
    amplitudeSecretKey: 'cc3692e9456a005507a6a9de9029c83f',
    sentryDSN:
      'https://b4a2bbadd2bc4b5d973dc73d5116e83c@o946535.ingest.sentry.io/5896615',
    hotjarID: 3006629,
  },
  client: {
    amplitudeApiKey: '9a3651827b9713f322c55a5219fb4fe9',
    amplitudeSecretKey: '16478566d238e703adf70868e32b8827',
    sentryDSN:
      'https://85b5bbe1d0e542aa89b5df722e70f149@o946535.ingest.sentry.io/5895530',
  },
};

const dev = {
  agent: {
    amplitudeApiKey: '6c9dbad8cf4b09a8e928183de73effb1',
    amplitudeSecretKey: '5cc8bb1970a76cb021b056c9a1864356',
    sentryDSN:
      'https://a93e843efe7f44fcb24c1357126892e4@o946535.ingest.sentry.io/5896612',
    hotjarID: 3057168,
  },
  client: {
    amplitudeApiKey: '5026b694f3ab9920cb64ae8c1ab4fa04',
    amplitudeSecretKey: 'cb0d48b1cee3b345386a6906f2511091',
    sentryDSN:
      'https://bd85dd15e6bb42f0859a069c04873efd@o946535.ingest.sentry.io/5895575',
  },
};

const environmentConfig = isDev() ? dev : prod;

export const config: TrackingConfig = isClient
  ? environmentConfig.client
  : environmentConfig.agent;
