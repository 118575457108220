import React from 'react';
import EmptyTable from './EmptyTable';
import { LoadMoreTableProps } from './types';
import { LoadMore } from './pagination/components/LoadMore';
import { Container, StyledTable } from './styledComponents';

export const LoadMoreTable: React.FC<LoadMoreTableProps> = ({
  className,
  columns,
  emptyDescription,
  data: fetchedData,
  locale,
  isLoading,
  rowKey,
  sortDirections,
  totalDataCounts,
  onRow,
  onRowClick,
  onChange,
  onClickLoadMore,
}) => {
  return (
    <Container className={`table ${className}`}>
      <StyledTable
        columns={columns}
        dataSource={fetchedData}
        locale={{
          emptyText: <EmptyTable description={emptyDescription} />,
          ...locale,
        }}
        pagination={false}
        loading={isLoading}
        rowKey={rowKey}
        onRow={(item: any) => {
          onRow?.(item);
          return {
            onClick: () => onRowClick && onRowClick(item),
          };
        }}
        sortDirections={sortDirections ?? ['ascend', 'descend']}
        onChange={onChange}
      />
      {onClickLoadMore && (
        <LoadMore
          total={totalDataCounts ?? fetchedData.length}
          size={fetchedData.length}
          onClickHandler={() => onClickLoadMore()}
        />
      )}
    </Container>
  );
};
