import React, { ReactNode } from 'react';
import { ModalProps } from 'antd/lib/modal';
import { CancelIcon, Spacer } from 'shared/ui';
import { ReactComponent as Rocket } from 'shared/ui/assets/backgrounds/rocket.svg';
import { ReactComponent as Circles } from 'shared/ui/assets/backgrounds/bg_circles.svg';
import {
  Modal,
  ModalBodyWrapper,
  ModalBody,
  RocketWrapper,
  CirclesWrapper,
} from './styledComponents';

type WizardModalProps = ModalProps & {
  header?: React.ReactElement;
  children: ReactNode;
  headerHeight?: number;
};

const WizardModal: React.FC<WizardModalProps> = ({
  closeIcon = <CancelIcon />,
  children,
  headerHeight = 80,
  header,
  ...otherProps
}: WizardModalProps) => {
  return (
    <Modal
      closeIcon={closeIcon}
      {...otherProps}
      footer={null}
      title={header}
      bodyStyle={
        header && {
          paddingTop: 0,
        }
      }
      $headerHeight={headerHeight}
    >
      <ModalBodyWrapper>
        <ModalBody>
          {children}
          <Spacer axis="vertical" size={64} />
        </ModalBody>
        <CirclesWrapper>
          <Circles />
        </CirclesWrapper>
        <RocketWrapper>
          <Rocket />
        </RocketWrapper>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default WizardModal;
