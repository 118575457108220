import React from 'react';
import { PaginationProps } from 'antd';
import { TABLE_SIZE_KEY } from 'client-web/modules/agents/consts';
import Pagination from './pagination';
import { Container, StyledTable } from './styledComponents';
import EmptyTable from './EmptyTable';
import { PaginationTableProps } from './types';
import { useTable } from './hooks/useTable';

/**
 * @deprecated This Table component is deprecated. Please use LoadMoreTable instead.
 */
export const Table: React.FC<PaginationTableProps> = ({
  data = [],
  columns,
  locale,
  emptyDescription,
  isLoading = false,
  rowKey,
  nextPage,
  onRowClick,
  sortDirections,
  className,
  showSizeChanger,
  hideOnSinglePage,
  resetPageNumberWithDataUpdate,
  onChange,
  onRow,
  pagination = true,
}) => {
  const { tableSize, table, onPageChange, setTableSize } = useTable({
    data,
    nextPage,
    resetPageNumberWithDataUpdate,
    tableSizeKey: TABLE_SIZE_KEY,
  });

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (
    _current,
    pageSize,
  ) => {
    setTableSize(pageSize);
  };

  const totalDataSize = data.length + (nextPage ? 1 : 0);
  const start = (table.currentPage - 1) * tableSize;
  const sliceData = data.slice(start, start + tableSize);

  return (
    <Container className={`table ${className}`}>
      <StyledTable
        columns={columns}
        dataSource={sliceData}
        locale={{
          emptyText: <EmptyTable description={emptyDescription} />,
          ...locale,
        }}
        pagination={false}
        loading={isLoading ?? table.loading}
        rowKey={rowKey}
        onRow={(item: any) => {
          onRow?.(item);
          return {
            onClick: () => onRowClick && onRowClick(item),
          };
        }}
        sortDirections={sortDirections ?? ['ascend', 'descend']}
        onChange={onChange}
      />
      {pagination && (
        <Pagination
          current={table.currentPage}
          pageSize={tableSize}
          total={totalDataSize}
          onChange={onPageChange}
          onShowSizeChange={onShowSizeChange}
          showSizeChanger={showSizeChanger}
          hideOnSinglePage={hideOnSinglePage}
        />
      )}
    </Container>
  );
};
