import styled from 'styled-components';
import { Pagination } from 'antd';
import { Brand, Gray } from '../../themes/colors';
import Typography from '../Typography';

export const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  background: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  .ant-pagination-item {
    border-radius: 8px;
    border: none;
    font-size: 12px;

    & p {
      color: ${Gray[500]};
    }

    &.ant-pagination-item-active {
      background-color: ${Brand[50]};

      & p {
        color: ${Brand[600]};
      }
    }
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-radius: 4px;
    border-color: ${Gray[300]};
    color: ${Gray[700]};

    &:disabled {
      opacity: 1;
      border-color: ${Gray[200]};
      color: ${Gray[300]};
    }
  }

  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: ${Gray[500]};
  }

  @media (max-width: 1440px) {
    &.narrow {
      .ant-pagination-item {
        margin-right: 5px;
        min-width: 25px;
      }

      .ant-pagination-prev > div,
      .ant-pagination-next > div {
        padding: 8px;
      }
    }
  }
`;

export const ButtonContainer = styled.div<{ $borderColor: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  height: 100%;
  padding: 0px 14px;
  border: 1px solid ${({ $borderColor }) => $borderColor};
`;

export const PagerContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const ResponsivePaginatedTypography = styled(Typography)<{
  $direction: 'next' | 'previous';
}>`
  ${({ $direction }) =>
    $direction === 'next' ? 'margin-right: 12px' : 'margin-left: 12px'};

  @media (max-width: 1440px) {
    font-size: 12px;
    line-height: 1;
    ${({ $direction }) =>
      $direction === 'next' ? 'margin-right: 2px' : 'margin-left: 2px'};
  }
`;
