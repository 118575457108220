import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { handleScale } from './utils';
import { ScaleType } from './types';

type BaseButtonProps = {
  $baseProps: CSSProperties;
  $hoverProps: CSSProperties;
  $focusProps: CSSProperties;
  $disabledProps: CSSProperties;
  $scale: ScaleType;
  $minWidth?: number | string;
  $padding?: string;
};

const BaseButton = styled(Button)<BaseButtonProps>(
  ({
    $scale,
    $minWidth,
    $padding,
    $baseProps,
    $hoverProps,
    $focusProps,
    $disabledProps,
  }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...handleScale($scale, $padding),
    span: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      svg: {
        height: '100%',
      },
    },
    minWidth: $minWidth ?? 180,
    ...$baseProps,
    '&.ant-btn:not([disabled]):hover': {
      ...$hoverProps,
    },
    '&.ant-btn:not([disabled]):focus': {
      ...$focusProps,
    },
    '&.ant-btn[disabled]': {
      ...$disabledProps,
    },
  }),
);

export const CircleStyle = styled(BaseButton)`
  && {
    border-radius: 50%;

    &.ant-btn-round {
      border-radius: 50%;
    }
  }
`;

export const SquareStyle = styled(BaseButton)`
  && {
    border-radius: 8px;

    &.ant-btn-round {
      border-radius: 8px;
    }
  }
`;
