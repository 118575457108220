import styled from 'styled-components';
// this kind of import help to avoid an error `Cannot create styled-component for component: undefined` when running the unit test
import { Row } from 'shared/ui/components';
import { Pagination } from 'antd';
import { Background, Secondary, Neutral } from 'client-web/themes/v2/colors';
import { hexToRGB } from 'shared/utils/color';
import { V2Button } from '../../../index';

const DEFAULT_PAGINATED_SIZE = 40;

export const StyledPagination = styled(Pagination)`
  height: 68px;
  background: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  padding-top: 12px;
  padding-left: 24px;

  .ant-pagination-item {
    border-radius: 8px;
    border: 1px solid transparent;
    width: ${DEFAULT_PAGINATED_SIZE}px;
    height: ${DEFAULT_PAGINATED_SIZE}px;
    background: ${Neutral[0]};

    &.ant-pagination-item-active {
      background-color: ${Background.nav[4]};
    }

    :not(.ant-pagination-item-active):hover {
      background: ${Background.nav[4]};
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    height: ${DEFAULT_PAGINATED_SIZE}px;
  }

  .pagination-button {
    border: 1px solid ${Secondary.light};

    // TODO: remove this if we revised the base icon
    path {
      fill: ${Secondary.medium[1]};
      stroke: transparent;
    }
  }

  .ant-pagination-disabled {
    .pagination-button {
      border: 1px solid ${hexToRGB(Secondary.light, 0.3)}!important;

      path {
        fill: ${hexToRGB(Secondary.dark, 0.3)};
      }
    }
  }

  .ant-pagination-options {
    .ant-select-selector {
      align-items: center;
      height: ${DEFAULT_PAGINATED_SIZE}px;
      border-radius: 8px;
      padding: 9px 19.5px;
      border: 2px solid ${Secondary.medium[2]};

      .ant-select-selection-item {
        color: ${Secondary.medium[2]};
        font-weight: 700;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  width: ${DEFAULT_PAGINATED_SIZE}px;
  height: ${DEFAULT_PAGINATED_SIZE}px;
`;

export const PagerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const LoadMoreContainer = styled(Row)`
  padding: 16px 24px 0 24px;
`;

export const StyledButton = styled(V2Button)`
  border-color: ${Secondary.medium['2']};
`;
