import React from 'react';
import { Typography } from 'shared';
import { Gray } from 'client-web/themes/v2';
import { EmptyTableContainer } from './styledComponents';

type Props = {
  description?: React.ReactNode;
};

const EmptyTable: React.FC<Props> = ({ description }) => {
  return (
    <EmptyTableContainer>
      <img alt="no-data" src={`${process.env.PUBLIC_URL}/images/no_data.png`} />
      <Typography variant="subtitle" component="h1" color={Gray[500]}>
        No results found.
      </Typography>
      {description}
    </EmptyTableContainer>
  );
};

export default React.memo(EmptyTable);
