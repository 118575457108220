import { hexToRGB } from 'shared/utils/color';
import Brand from './brand';
import Indigo from './indigo';
import Success from './success';

const Gradient = {
  700: 'linear-gradient(98.8deg, #009170 29.48%, #3538CD 133.95%)',
  900: 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(98.8deg, #009170 29.48%, #3538CD 133.95%)',
  Graphic: {
    2: `linear-gradient(300.24deg, ${Success[300]} 2.37%, #4E81CC 69.06%, ${Indigo[700]} 102.56%);`,
    3: 'linear-gradient(113.09deg, rgba(255, 255, 255, 0.186) -26.21%, rgba(224, 242, 254, 0.3) 95.97%), linear-gradient(113.09deg, rgba(255, 255, 255, 0.62) -26.21%, #5FCEB1 111.71%)',
    4: 'linear-gradient(299.74deg, #479FB0 11.16%, #2E67BF 40.93%, #273ACC 63.9%, #6325CC 92.82%);',
  },
  Primary: {
    50: `linear-gradient(98.8deg, ${hexToRGB(
      Brand[500],
      0.16,
    )} 29.48%, ${hexToRGB(Indigo[500], 0.16)} 133.95%), #FFFFFF`,
    100: `linear-gradient(98.8deg, ${hexToRGB(
      Brand[500],
      0.3,
    )} 29.48%, ${hexToRGB(Indigo[700], 0.3)} 133.95%), #FFFFFF`,
    500: `linear-gradient(98.8deg, ${hexToRGB(
      Brand[500],
      0.8,
    )} 29.48%, ${hexToRGB(Indigo[700], 0.8)} 133.95%), #FFFFFF`,
    700: `linear-gradient(98.8deg, ${Brand[500]} 29.48%, ${Indigo[700]} 133.95%)`,
    900: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(98.8deg, ${Brand[500]} 29.48%, ${Indigo[700]} 133.95%)`,
  },
  Chart: {
    2: 'linear-gradient(180deg, rgba(255, 114, 182, 0.16) 0%, rgba(255, 198, 225, 0) 100%);',
  },
};

export default Gradient;
