import styled from 'styled-components';

export const LoadingContainer = styled.div<{ $height?: string }>`
  width: 100%;
  height: ${({ $height }) => $height || '100vh'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VisibleDiv = styled.div<{ $visible: boolean }>`
  height: ${({ $visible }) => ($visible ? '100%' : 0)};
  overflow: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
`;
