import { MouseEventHandler } from 'react';
import { ReactComponent as HeartIcon } from './base/heart.svg';
import { ReactComponent as AirplayIcon } from './base/airplay.svg';
import { ReactComponent as CheckCircleIcon } from './base/check_circle.svg';
import { ReactComponent as HeadphonesIcon } from './base/headphones.svg';
import { ReactComponent as CalendarIcon } from './base/calendar.svg';
import { ReactComponent as BookOpenIcon } from './base/book_open.svg';
import { ReactComponent as StarIcon } from './base/star.svg';
import { ReactComponent as DollarIcon } from './base/dollar_sign.svg';
import { ReactComponent as XCircleIcon } from './base/x_circle.svg';
import { ReactComponent as BriefcaseIcon } from './base/briefcase.svg';
import { ReactComponent as ArrowDownIcon } from './base/arrow_down.svg';
import { ReactComponent as AlertCircleIcon } from './base/alert_circle.svg';
import { ReactComponent as AlertTriangleIcon } from './base/alert_triangle.svg';
import { ReactComponent as CreditCardIcon } from './base/credit_card.svg';
import { ReactComponent as ArrowLeftIcon } from './base/arrow_left.svg';
import { ReactComponent as ArrowRightIcon } from './base/arrow_right.svg';
import { ReactComponent as LoaderIcon } from './base/ic_loader.svg';
import { ReactComponent as ArrowDownRightIcon } from './base/ic_arrow_down_right.svg';
import { ReactComponent as CheckIcon } from './base/ic_check.svg';
import { ReactComponent as DownloadIcon } from './base/download.svg';
import { ReactComponent as ClockIcon } from './base/clock.svg';
import { ReactComponent as UsersIcon } from './base/users.svg';
import { ReactComponent as InfoIcon } from './base/info.svg';
import { ReactComponent as EditIcon } from './base/edit.svg';
import { ReactComponent as UserPlusIcon } from './base/user_plus.svg';
import { ReactComponent as CancelIcon } from './base/cancel.svg';
import { ReactComponent as ThunderIcon } from './base/surge.svg';
import { ReactComponent as UserIcon } from './base/user.svg';
import { ReactComponent as GroupIcon } from './base/group.svg';
import { ReactComponent as ChevronDownIcon } from './base/chevron_down.svg';
import { ReactComponent as ChevronUpIcon } from './base/chevron_up.svg';
import { ReactComponent as ChevronLeftIcon } from './base/chevron_left.svg';
import { ReactComponent as ChevronRightIcon } from './base/chevron_right.svg';
import { ReactComponent as RocketIcon } from './base/rocket.svg';
import { ReactComponent as ActivityIcon } from './base/activity.svg';
import { ReactComponent as TrendingUpIcon } from './base/trending_up.svg';
import { ReactComponent as PhoneOffIcon } from './base/phone_off.svg';
import { ReactComponent as ExternalLinkIcon } from './base/external_link.svg';
import { ReactComponent as SendIcon } from './base/send.svg';
import { ReactComponent as ArrowUpRightIcon } from './base/arrow_up_right.svg';
import { ReactComponent as FrownIcon } from './base/frown.svg';
import { ReactComponent as MehIcon } from './base/meh.svg';
import { ReactComponent as SmileIcon } from './base/smile.svg';
import { ReactComponent as LightningIcon } from './base/lightning.svg';
import { ReactComponent as SearchIcon } from './base/search.svg';
import { ReactComponent as ApertureIcon } from './base/aperture.svg';
import { ReactComponent as ArchiveIcon } from './base/archive.svg';
import { ReactComponent as ArrowDownCircleIcon } from './base/arrow_down_circle.svg';
import { ReactComponent as HomeIcon } from './base/home.svg';
import { ReactComponent as TrelloIcon } from './base/trello.svg';
import { ReactComponent as StarBgIcon } from './base/star-background.svg';
import { ReactComponent as FileTextIcon } from './base/file_text.svg';
import { ReactComponent as PenToolIcon } from './base/pen_tool.svg';
import { ReactComponent as CoinStackIcon } from './base/coin_stack.svg';
import { ReactComponent as MonitorIcon } from './base/ic_monitor.svg';
import { ReactComponent as MobileIcon } from './base/ic_mobile.svg';
import { ReactComponent as KeyboardIcon } from './base/keyboard.svg';
import { ReactComponent as RotateCcwIcon } from './base/rotate_ccw.svg';
import { ReactComponent as UploadCloudIcon } from './base/upload_cloud.svg';
import { ReactComponent as InformationIcon } from './base/ic_information.svg';
import { ReactComponent as HelpCircleIcon } from './base/help_circle.svg';
import { ReactComponent as MicIcon } from './base/mic.svg';
import { ReactComponent as PlayIcon } from './base/play.svg';
import { ReactComponent as SquareIcon } from './base/square.svg';
import { ReactComponent as TrashIcon } from './base/trash.svg';
import { ReactComponent as MailIcon } from './base/mail.svg';
import { ReactComponent as LockIcon } from './base/lock.svg';
import { ReactComponent as UploadIcon } from './base/upload.svg';
import { ReactComponent as BellIcon } from './base/bell.svg';
import { ReactComponent as LinkIcon } from './base/link.svg';
import { ReactComponent as GoUpIcon } from './base/go_up.svg';
import { ReactComponent as GoDownIcon } from './base/go_down.svg';
import { ReactComponent as PhoneIcon } from './base/phone.svg';
import { ReactComponent as FlagMarkIcon } from './base/flag_mark.svg';
import { ReactComponent as PhoneMailIcon } from './base/phone_mail.svg';
import { ReactComponent as ArrowCircleBrokenRightOutIcon } from './base/arrow_circle_broken_right_out.svg';
import { ReactComponent as ArrowCircleBrokenRightIcon } from './base/arrow_circle_broken_right.svg';
import { ReactComponent as ShuffleIcon } from './base/shuffle.svg';
import { ReactComponent as PopupInfoIcon } from './base/popup_info.svg';
import { ReactComponent as MinusIcon } from './base/minus.svg';
import { ReactComponent as BookIcon } from './base/book.svg';
import { ReactComponent as ModalPopupIcon } from './base/modal-popup.svg';
import { ReactComponent as CoffeeIcon } from './base/coffee.svg';
import { ReactComponent as LunchIcon } from './base/lunch.svg';

export const BaseIconTypes = {
  heart: 'heart',
  airplay: 'airplay',
  checkCircle: 'checkCircle',
  headphones: 'headphones',
  calendar: 'calendar',
  bookOpen: 'bookOpen',
  star: 'star',
  dollarSign: 'dollarSign',
  xCircle: 'xCircle',
  briefcase: 'briefcase',
  arrowDown: 'arrowDown',
  alertCircle: 'alertCircle',
  alertTriangle: 'alertTriangle',
  creditCard: 'creditCard',
  arrowLeft: 'arrowLeft',
  arrowRight: 'arrowRight',
  loader: 'loader',
  arrowDownRight: 'arrowDownRight',
  check: 'check',
  download: 'download',
  clock: 'clock',
  users: 'users',
  info: 'info',
  edit: 'edit',
  userPlus: 'userPlus',
  cancel: 'cancel',
  thunder: 'thunder',
  user: 'user',
  group: 'group',
  chevronDown: 'chevronDown',
  chevronUp: 'chevronUp',
  chevronLeft: 'chevronLeft',
  chevronRight: 'chevronRight',
  rocket: 'rocket',
  activity: 'activity',
  trendingUp: 'trendingUp',
  phoneOff: 'phoneOff',
  externalLink: 'externalLink',
  send: 'send',
  arrowUpRight: 'arrowUpRight',
  frown: 'frown',
  meh: 'meh',
  smile: 'smile',
  lightning: 'lightning',
  search: 'search',
  aperture: 'aperture',
  archive: 'archive',
  arrowDownCircle: 'arrowDownCircle',
  home: 'home',
  trello: 'trello',
  starBg: 'starBg',
  fileText: 'fileText',
  penTool: 'penTool',
  coinStack: 'coinStack',
  keyboard: 'keyboard',
  rotateCcw: 'rotateCcw',
  uploadCloud: 'uploadCloud',
  helpCircle: 'helpCircle',
  information: 'information',
  mic: 'mic',
  play: 'play',
  square: 'square',
  trash: 'trash',
  mail: 'mail',
  lock: 'lock',
  upload: 'upload',
  monitor: 'monitor',
  mobile: 'mobile',
  bell: 'bell',
  link: 'link',
  goUp: 'goUp',
  goDown: 'goDown',
  phone: 'phone',
  flagMark: 'flagMark',
  phoneMail: 'phoneMail',
  arrowCircleBrokenRightOut: 'arrowCircleBrokenRightOut',
  arrowCircleBrokenRight: 'arrowCircleBrokenRight',
  shuffle: 'shuffle',
  popupInfo: 'popupInfo',
  minus: 'minus',
  book: 'book',
  modalPopup: 'modalPopup',
  coffee: 'coffee',
  lunch: 'lunch',
} as const;

export type BaseIconType = keyof typeof BaseIconTypes;

export type BaseIconProps = {
  icon: BaseIconType;
  color?: string;
  stroke?: string;
  size?: number;
  className?: string;
  onClick?: MouseEventHandler;
};

export const BaseIconComponentMaps = {
  [BaseIconTypes.heart]: HeartIcon,
  [BaseIconTypes.airplay]: AirplayIcon,
  [BaseIconTypes.checkCircle]: CheckCircleIcon,
  [BaseIconTypes.headphones]: HeadphonesIcon,
  [BaseIconTypes.calendar]: CalendarIcon,
  [BaseIconTypes.bookOpen]: BookOpenIcon,
  [BaseIconTypes.star]: StarIcon,
  [BaseIconTypes.dollarSign]: DollarIcon,
  [BaseIconTypes.xCircle]: XCircleIcon,
  [BaseIconTypes.briefcase]: BriefcaseIcon,
  [BaseIconTypes.arrowDown]: ArrowDownIcon,
  [BaseIconTypes.alertCircle]: AlertCircleIcon,
  [BaseIconTypes.alertTriangle]: AlertTriangleIcon,
  [BaseIconTypes.creditCard]: CreditCardIcon,
  [BaseIconTypes.arrowLeft]: ArrowLeftIcon,
  [BaseIconTypes.arrowRight]: ArrowRightIcon,
  [BaseIconTypes.loader]: LoaderIcon,
  [BaseIconTypes.arrowDownRight]: ArrowDownRightIcon,
  [BaseIconTypes.check]: CheckIcon,
  [BaseIconTypes.download]: DownloadIcon,
  [BaseIconTypes.clock]: ClockIcon,
  [BaseIconTypes.users]: UsersIcon,
  [BaseIconTypes.info]: InfoIcon,
  [BaseIconTypes.edit]: EditIcon,
  [BaseIconTypes.userPlus]: UserPlusIcon,
  [BaseIconTypes.cancel]: CancelIcon,
  [BaseIconTypes.thunder]: ThunderIcon,
  [BaseIconTypes.user]: UserIcon,
  [BaseIconTypes.group]: GroupIcon,
  [BaseIconTypes.chevronDown]: ChevronDownIcon,
  [BaseIconTypes.chevronUp]: ChevronUpIcon,
  [BaseIconTypes.chevronLeft]: ChevronLeftIcon,
  [BaseIconTypes.chevronRight]: ChevronRightIcon,
  [BaseIconTypes.rocket]: RocketIcon,
  [BaseIconTypes.activity]: ActivityIcon,
  [BaseIconTypes.trendingUp]: TrendingUpIcon,
  [BaseIconTypes.phoneOff]: PhoneOffIcon,
  [BaseIconTypes.externalLink]: ExternalLinkIcon,
  [BaseIconTypes.send]: SendIcon,
  [BaseIconTypes.arrowUpRight]: ArrowUpRightIcon,
  [BaseIconTypes.frown]: FrownIcon,
  [BaseIconTypes.meh]: MehIcon,
  [BaseIconTypes.smile]: SmileIcon,
  [BaseIconTypes.lightning]: LightningIcon,
  [BaseIconTypes.search]: SearchIcon,
  [BaseIconTypes.aperture]: ApertureIcon,
  [BaseIconTypes.archive]: ArchiveIcon,
  [BaseIconTypes.arrowDownCircle]: ArrowDownCircleIcon,
  [BaseIconTypes.home]: HomeIcon,
  [BaseIconTypes.trello]: TrelloIcon,
  [BaseIconTypes.starBg]: StarBgIcon,
  [BaseIconTypes.fileText]: FileTextIcon,
  [BaseIconTypes.penTool]: PenToolIcon,
  [BaseIconTypes.coinStack]: CoinStackIcon,
  [BaseIconTypes.keyboard]: KeyboardIcon,
  [BaseIconTypes.rotateCcw]: RotateCcwIcon,
  [BaseIconTypes.uploadCloud]: UploadCloudIcon,
  [BaseIconTypes.helpCircle]: HelpCircleIcon,
  [BaseIconTypes.information]: InformationIcon,
  [BaseIconTypes.mic]: MicIcon,
  [BaseIconTypes.play]: PlayIcon,
  [BaseIconTypes.square]: SquareIcon,
  [BaseIconTypes.trash]: TrashIcon,
  [BaseIconTypes.mail]: MailIcon,
  [BaseIconTypes.lock]: LockIcon,
  [BaseIconTypes.upload]: UploadIcon,
  [BaseIconTypes.monitor]: MonitorIcon,
  [BaseIconTypes.mobile]: MobileIcon,
  [BaseIconTypes.bell]: BellIcon,
  [BaseIconTypes.link]: LinkIcon,
  [BaseIconTypes.goUp]: GoUpIcon,
  [BaseIconTypes.goDown]: GoDownIcon,
  [BaseIconTypes.phone]: PhoneIcon,
  [BaseIconTypes.flagMark]: FlagMarkIcon,
  [BaseIconTypes.phoneMail]: PhoneMailIcon,
  [BaseIconTypes.arrowCircleBrokenRightOut]: ArrowCircleBrokenRightOutIcon,
  [BaseIconTypes.arrowCircleBrokenRight]: ArrowCircleBrokenRightIcon,
  [BaseIconTypes.shuffle]: ShuffleIcon,
  [BaseIconTypes.popupInfo]: PopupInfoIcon,
  [BaseIconTypes.minus]: MinusIcon,
  [BaseIconTypes.book]: BookIcon,
  [BaseIconTypes.modalPopup]: ModalPopupIcon,
  [BaseIconTypes.coffee]: CoffeeIcon,
  [BaseIconTypes.lunch]: LunchIcon,
};
