const Background = {
  main: '#F4F6F8',
  aht: '#A6C9C3',
  nav: {
    1: '#DBF9FF',
    2: '#FFEECE',
    3: '#E5F9F0',
    4: '#E8E8FF',
    5: '#F9F5FF',
  },
} as const;

export default Background;
