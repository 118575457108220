import React from 'react';
import { StyledBadge } from './styledComponents';

interface IBadgeProps {
  content: React.ReactNode;
  backgroundColor?: string;
  className?: string;
}

const Badge: React.FC<IBadgeProps> = ({
  content,
  backgroundColor,
  className,
}) => (
  <StyledBadge className={className} backgroundColor={backgroundColor}>
    {content}
  </StyledBadge>
);

export default React.memo(Badge);
