import React from 'react';
import { ISOCountry, ISOCountryKeys } from '@agentsonly/models';
import USFlagIcon from '../../assets/flags/USA.png';
import CAFlagIcon from '../../assets/flags/Canada.png';
import PHFlagIcon from '../../assets/flags/Philippines.png';
import MXFlagIcon from '../../assets/flags/Mexico.png';
import RectUSFlagIcon from '../../assets/flags/rectangle/USA.svg';
import RectCAFlagIcon from '../../assets/flags/rectangle/Canada.svg';
import RectPHFlagIcon from '../../assets/flags/rectangle/Philippines.svg';
import RectMXFlagIcon from '../../assets/flags/rectangle/Mexico.svg';

const flags: Record<ISOCountry, string> = {
  [ISOCountryKeys.CA]: CAFlagIcon,
  [ISOCountryKeys.MX]: MXFlagIcon,
  [ISOCountryKeys.PH]: PHFlagIcon,
  [ISOCountryKeys.US]: USFlagIcon,
};

const rectangleFlags: Record<ISOCountry, string> = {
  [ISOCountryKeys.CA]: RectCAFlagIcon,
  [ISOCountryKeys.MX]: RectMXFlagIcon,
  [ISOCountryKeys.PH]: RectPHFlagIcon,
  [ISOCountryKeys.US]: RectUSFlagIcon,
};

type Props = {
  country: ISOCountry;
  isRectangle?: boolean;
};

const FlagIcon: React.FC<Props> = ({ country, isRectangle, ...props }) => (
  <img
    src={isRectangle ? rectangleFlags[country] : flags[country]}
    alt={country}
    {...props}
  />
);

export default React.memo(FlagIcon);
