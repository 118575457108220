import { Gray } from 'shared/ui/nuba/themes/colors';
import Icon from '@ant-design/icons';
import { StyledIcon } from './styledComponents';
import {
  BaseIconComponentMaps,
  BaseIconProps,
  BaseIconTypes,
} from './BaseIcon';
import {
  IllustratedIconTypes,
  IllustratedIconProps,
  IllustratedIconComponentMaps,
} from './IllustratedIcon';

const DEFAULT_ICON_SIZE = 20;

// see @https://www.figma.com/file/XBlYQK8vPfsP5saOOQyoGJ/AO-Design-System-V2?node-id=1027%3A7346&t=Q6ofljvxi91k3BKL-4
export const BaseIcon = ({
  icon = BaseIconTypes.heart,
  color = 'white',
  stroke = Gray[900],
  size = DEFAULT_ICON_SIZE,
  className,
  onClick,
}: BaseIconProps) => {
  const BaseIconComponent = BaseIconComponentMaps[icon];
  return (
    <StyledIcon
      className={className}
      component={BaseIconComponent}
      style={{
        color,
        fontSize: size,
      }}
      stroke={stroke}
      onClick={onClick}
    />
  );
};

// see @https://www.figma.com/file/XBlYQK8vPfsP5saOOQyoGJ/AO-Design-System-V2?node-id=7626%3A104092&t=Q6ofljvxi91k3BKL-4
export const IllustratedIcon = ({
  icon = IllustratedIconTypes.star,
  color = 'transparent',
  size = DEFAULT_ICON_SIZE,
}: IllustratedIconProps) => {
  const IllustratedIconComponent = IllustratedIconComponentMaps[icon];
  return (
    <Icon
      component={IllustratedIconComponent}
      style={{
        color,
        fontSize: size,
      }}
    />
  );
};
