import * as React from 'react';
import { DateFnsPicker } from 'shared/ui/components';
import {
  PickerTimeProps,
  RangePickerTimeProps,
} from 'antd/es/date-picker/generatePicker';

const { RangePicker: InternalRangePicker } = DateFnsPicker;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TimePickerProps
  extends Omit<PickerTimeProps<Date>, 'picker'> {}

export interface TimeRangePickerProps
  extends Omit<RangePickerTimeProps<Date>, 'picker'> {
  popupClassName?: string;
  picker: 'time' | 'date' | 'week' | 'month' | 'quarter' | 'year' | undefined;
}

const RangeFnsPicker = React.forwardRef<any, TimeRangePickerProps>(
  (props, ref) => (
    <InternalRangePicker
      {...props}
      picker={props.picker}
      dropdownClassName={props.popupClassName}
      mode={undefined}
      ref={ref}
    />
  ),
);

const TimeFnsPicker = React.forwardRef<any, TimePickerProps>((props, ref) => {
  return <DateFnsPicker {...props} picker="time" mode={undefined} ref={ref} />;
});

TimeFnsPicker.displayName = 'TimePicker';

type MergedTimeFnsPicker = typeof TimeFnsPicker & {
  RangeFnsPicker: typeof RangeFnsPicker;
};

(TimeFnsPicker as MergedTimeFnsPicker).RangeFnsPicker = RangeFnsPicker;

export default TimeFnsPicker as MergedTimeFnsPicker;
