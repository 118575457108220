import { SizeType } from 'antd/lib/config-provider/SizeContext';
import styled from 'styled-components';

const TAG_SIZE = {
  large: 'height: 48px; width: 48px;',
  middle: 'height: 32px; width: 32px;',
  small: 'height: 24px; width: 24px;',
};

export const Tag = styled.span<{
  color?: string;
  size?: SizeType;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color || 'inherit'};
  ${({ size }) => (size ? TAG_SIZE?.[size] : TAG_SIZE.large)};
  border-radius: 50%;
`;
