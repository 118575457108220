import React, { useCallback } from 'react';
import { RadioChangeEvent } from 'antd';
import { useTextInput } from 'shared/hooks';
import Typography from '../Typography';
import {
  StyledRadioGroup,
  StyledRadio,
  StyledRadioGroupProps,
  StyledRadioProps,
} from './styledComponents';

type Props = StyledRadioGroupProps & {
  name?: string;
  requiredInitialValue?: boolean;
  value?: string;
  values: string[];
  onChange?: (event: RadioChangeEvent) => void;
  labels: { [index: string]: string | React.ReactNode };
  colors?: { [index: string]: string };
  radioProps?: StyledRadioProps;
  disabled?: boolean;
  flexColumn?: boolean;
  gap?: number;
  defaultValue?: string;
};

const RadioGroup: React.FC<Props> = ({
  name,
  values,
  value: propValue,
  defaultValue,
  onChange,
  labels,
  colors,
  radioProps,
  requiredInitialValue = true,
  flexColumn,
  gap,
  ...rest
}) => {
  const [value, handleValueChange] = useTextInput(
    requiredInitialValue ? values[0] : undefined,
  );

  const handleChange = useCallback(
    (event: RadioChangeEvent) => {
      handleValueChange(event.target.value);

      if (onChange) {
        onChange(event);
      }
    },
    [handleValueChange, onChange],
  );

  const controlledValue = requiredInitialValue ? propValue ?? value : propValue;

  return (
    <StyledRadioGroup
      defaultValue={defaultValue}
      onChange={handleChange}
      value={controlledValue}
      flexColumn={flexColumn}
      gap={gap}
      name={name}
      {...rest}
    >
      {values.map((currentValue, i) => {
        return (
          <StyledRadio
            key={`radio-key-${i}`}
            value={currentValue}
            $radioColor={colors?.[currentValue]}
            {...radioProps}
          >
            {typeof labels[currentValue] === 'string' ? (
              <Typography component="span" variant="inputText">
                {labels[currentValue]}
              </Typography>
            ) : (
              labels[currentValue]
            )}
          </StyledRadio>
        );
      })}
    </StyledRadioGroup>
  );
};

export default React.memo(RadioGroup);
