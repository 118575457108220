import styled from 'styled-components';
import { Input } from 'antd';
import { baseColors } from '../../../theme';

export const StyledInput = styled(Input)`
  border: ${(props) =>
    props.bordered !== false ? `1px solid ${baseColors.dividers}` : 'none'};
  border-radius: 4px;
`;

export const StyledPasswordInput = styled(Input.Password)`
  height: 48px;
`;
