const MILLION_SUFFIX = 'M';
const THOUSAND_SUFFIX = 'K';

/**
 * The `toFixed` function takes a number and a fraction as input and returns a string representation of
 * the number with the specified number of decimal places.
 * @param {number} value - The `value` parameter is a number that you want to format with a fixed
 * number of decimal places.
 * @param {number} fraction - The `fraction` parameter is the number of decimal places to round the
 * `value` to.
 * @returns The function `toFixed` returns a string representation of the number `value` with a fixed
 * number of decimal places specified by the `fraction` parameter.
 * @example
 * fraction = 2,
 * 2 => 2
 * 2.1 => 2.1
 * 2.12 => 2.12
 * 2.123 => 2.12
 */
export const toFixed = (value: number, fraction: number) => {
  const [int, decimal] = value.toString().split('.');

  if (decimal === undefined) {
    return int;
  }
  if (decimal.length < fraction) {
    return `${int}.${decimal}`;
  }
  return value.toFixed(fraction);
};

/**
 * format number to readable string
 * @param value
 * @param minimumFractionDigits default 0
 * @param maximumFractionDigits default 2
 * @param useGrouping default false
 * @param locale default en-US
 * @returns
 */
export const formatNumber = (
  value: number,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
  useGrouping = false,
  locale = 'en-US',
): string =>
  new Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
    useGrouping,
  }).format(value);

/** convert number value to shorten text representation.
 * @example 1_549 ==> 1.5K, 1_000_000 ==> 1M
 */
export const shortenNumber = (value: number) => {
  const isMillionNumber = Math.floor(value / 1000000) > 0;
  const isThousandNumber = Math.floor(value / 1000) > 0;

  const suffix = isMillionNumber
    ? MILLION_SUFFIX
    : isThousandNumber
    ? THOUSAND_SUFFIX
    : '';
  const divisor = isMillionNumber ? 1000000 : isThousandNumber ? 1000 : 1;
  const shortenValue = formatNumber(value / divisor, 0, 1, true);

  return `${shortenValue}${suffix}`;
};

export const sum = (arr: number[]) => arr.reduce((acc, num) => acc + num, 0);

/**
 * Rounds a number to the specified number of decimal places.
 *
 * @param num - The number to round.
 * @param places - The number of decimal places to round to.
 * @returns The rounded number.
 */
export const round = (num: number, places: number) =>
  Math.round((num + Number.EPSILON) * 10 ** places) / 10 ** places;

export const toNum = (value: number, defaultValue = 0) =>
  Number(value) || defaultValue;

export const getDecimalPlaces = (num: number): number => {
  const match = num.toString().match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) return 0;
  return match[1] ? match[1].length : 0;
};

// Ref: https://pipinghot.dev/snippet/check-if-a-value-is-a-number-in-javascript-and-typescript/
export const isNumeric = (n: any): boolean => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const getMaxMultipleOfOffset = (num: number, offset: number): number => {
  if (num < 0) return 0;

  return Math.floor(num / offset) * offset;
};
