import { colorsByVariant } from './config';
import { ButtonColorType, ButtonVariantType, Scales, ScaleType } from './types';

export const handleScale = (scale: ScaleType) => {
  switch (scale) {
    case Scales.sm:
      return {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
        padding: '8px 12px',
        height: '36px',
      };
    case Scales.md:
      return {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '21px',
        padding: '10px 18px',
        height: '41px',
      };
    case Scales.lg:
      return {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '21px',
        padding: '12px 20px',
        height: '45px',
      };
    // default lg
    default:
      return {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '21px',
        padding: '12px 20px',
        height: '45px',
      };
  }
};

export const getVariantColorProps = (
  variant: ButtonVariantType,
  btnColor: ButtonColorType,
) => {
  const { color, background, border, focus, hover, disabled, ...rest } =
    colorsByVariant[btnColor][variant];

  return {
    ...rest,
    color,
    background: background ?? 'transparent',
    border: border ?? 'none',
    hover: {
      ...hover,
      color: hover.color ?? color,
      background: hover.background ?? 'transparent',
      border: hover.border ?? border ?? '0',
    },
    focus: {
      ...focus,
      color: focus.color ?? color,
      background: focus.background ?? background ?? 'transparent',
      border: focus.border ?? border ?? '0',
    },
    disabled: {
      ...disabled,
      color: disabled.color ?? color,
      background: disabled.background ?? background ?? 'transparent',
      border: disabled.border ?? border ?? '0',
    },
  };
};
