export const Scales = {
  small: 'small',
  regular: 'regular',
} as const;

export type ScaleType = (typeof Scales)[keyof typeof Scales];

export const ButtonShape = {
  CIRCLE: 'circle',
  SQUARE: 'square',
} as const;

export type ButtonShapeType = (typeof ButtonShape)[keyof typeof ButtonShape];

export const ButtonVariant = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  LINK: 'link',
} as const;

export type ButtonVariantType =
  (typeof ButtonVariant)[keyof typeof ButtonVariant];

export const Buttons = {
  DEFAULT: 'default',
  BRAND: 'brand',
  ALERT: 'alert',
  GRADIENT: 'gradient', // for only auth (sing up/login)
  MAI: 'mai',
} as const;

export type ButtonType = (typeof Buttons)[keyof typeof Buttons];
