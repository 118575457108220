import { useState, useCallback } from 'react';

const useModal = (
  initialValue?: boolean,
): [boolean, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(initialValue || false);
  const closeModal = useCallback(() => setIsOpen(false), []);
  const openModal = useCallback(() => setIsOpen(true), []);
  return [isOpen, openModal, closeModal];
};

export default useModal;
