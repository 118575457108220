import styled from 'styled-components';
import { Neutral, Secondary } from '../../colors';

const BasePadding = `padding: 6px 18px`;

export const StyledTable = styled.table<{ $isLoading?: boolean }>`
  width: 100%;
  opacity: ${({ $isLoading }) => ($isLoading ? 0.5 : 1)};

  thead th {
    background: ${Neutral[0]};
    border-bottom: 1px solid ${Secondary.medium[1]};
    align-items: center;
    height: 44px;
    ${BasePadding};

    // default style: Body Tags/P4-Column Text Body
    color: ${Secondary.medium[2]};
    font-weight: 400;
    font-size: 14px;
    line-height: 18.23px;
  }

  tbody {
    background: ${Neutral[0]};

    tr {
      height: 64px;
    }

    td,
    th {
      ${BasePadding};
      border-bottom: 1px solid ${Secondary.medium[1]};
      word-break: break-word;

      // default style: Heading Tags/H5-Label
      color: ${Neutral[100]}
      font-weight: 700;
      font-size: 16px;
      line-height: 20.83px;
    }
  }
`;
