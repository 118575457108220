import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { ColumnType } from 'antd/lib/table';
import { GetRowKey, SortOrder, TableLocale } from 'antd/lib/table/interface';
import { Container, StyledTable } from './styledComponents';
import { Spinner } from '../Spinner';
import Pagination from '../Pagination';

interface PaginationOptions {
  showSizeChanger?: boolean;
  pageSizeOptions?: string[];
  hideOnSinglePage?: boolean;
  defaultPageSize?: number;
  onShowSizeChange?: (current: number, size: number) => void;
}

type Props = {
  id?: string;
  pageSize?: number;
  readonly data: any[];
  columns?: ColumnType<any>[];
  locale?: TableLocale;
  isLoading?: boolean;
  rowKey?: string | GetRowKey<any>;
  nextPage?: () => Promise<void>;
  onRowClick?: (item: any) => void;
  paginationOptions?: PaginationOptions;
  renderPaginationSuffix?: ReactElement;
  empty?: ReactElement;
  title?: React.ReactNode;
  sortDirections?: SortOrder[];
  className?: string;
  paginationShowLessItems?: boolean;
  resetPageNumberWithDataUpdate?: boolean;
};

const DataPaginatedTable: React.FC<Props> = ({
  id,
  pageSize = 10,
  data,
  columns,
  locale,
  isLoading = false,
  rowKey,
  nextPage,
  onRowClick,
  paginationOptions,
  renderPaginationSuffix,
  empty,
  title,
  sortDirections,
  className,
  paginationShowLessItems,
  resetPageNumberWithDataUpdate,
}) => {
  const [totalPages, setTotal] = useState(1);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const start = (currentPage - 1) * pageSize;
  const slice = data.slice(start, start + pageSize);

  useEffect(() => {
    const total = Math.ceil(data.length / pageSize);
    setTotal(total);
  }, [data, pageSize]);

  const onPageChange = useCallback(
    async (page: number) => {
      if (page > totalPages) {
        setLoading(true);
        if (typeof nextPage === 'function') {
          nextPage?.()
            .then(() => {
              setCurrentPage(page);
            })
            .catch((err) => console.error(err));
        }
      } else {
        setCurrentPage(page);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalPages],
  );

  useEffect(() => setLoading(false), [data]);

  useEffect(() => {
    if (resetPageNumberWithDataUpdate) {
      setCurrentPage(1);
    }
  }, [resetPageNumberWithDataUpdate, data]);

  const titleProps = title && { title: () => title };

  // if fetch data returns all data, data size won't change
  // but if firebase (fake) pagination, data size is unknown,
  // until fetch in the last page
  const totalDataSize = data.length + (nextPage ? 1 : 0);

  return (
    <Container className={className}>
      {data.length === 0 && !isLoading && empty ? (
        empty
      ) : (
        <>
          <StyledTable
            {...titleProps}
            id={id}
            columns={columns}
            dataSource={slice}
            locale={locale}
            pagination={false}
            loading={{
              indicator: <Spinner height="100%" />,
              spinning: loading || isLoading,
            }}
            rowKey={rowKey}
            onRow={(item: any) => ({
              onClick: () => onRowClick && onRowClick(item),
            })}
            sortDirections={sortDirections ?? ['ascend', 'descend']}
          />
          {slice && slice.length > 0 && (
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalDataSize}
              onChange={onPageChange}
              showLessItems={paginationShowLessItems}
              {...paginationOptions}
            />
          )}
          {renderPaginationSuffix ?? null}
        </>
      )}
    </Container>
  );
};

export default React.memo(DataPaginatedTable);
