import React from 'react';
import type { PaginationProps } from 'antd';
import { StyledPagination } from './components/styledComponents';
import PaginationButton from './components/PaginationButton';
import PaginationPager from './components/PaginationPager';

const itemRender: PaginationProps['itemRender'] = (
  pageNum,
  type,
  originalElement,
) => {
  if (type === 'prev') {
    return <PaginationButton direction="previous" />;
  }

  if (type === 'next') {
    return <PaginationButton direction="next" />;
  }

  if (type === 'page') {
    return <PaginationPager pageNum={pageNum} />;
  }

  return originalElement;
};

type Props = {
  current: number;
  pageSize: number;
  total: number;
  onChange: (page: number) => Promise<void>;
  showSizeChanger?: boolean;
  hideOnSinglePage?: boolean;
  onShowSizeChange?: (current: number, size: number) => void;
};

const Pagination: React.FC<Props> = ({
  current,
  pageSize,
  total,
  onChange,
  showSizeChanger = true,
  hideOnSinglePage = false,
  onShowSizeChange,
}) => (
  <StyledPagination
    current={current}
    pageSize={pageSize}
    total={total}
    onChange={onChange}
    itemRender={itemRender}
    showSizeChanger={showSizeChanger}
    hideOnSinglePage={hideOnSinglePage}
    onShowSizeChange={onShowSizeChange}
  />
);

export default React.memo(Pagination);
