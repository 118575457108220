const Secondary = {
  main: '#203D50',
  medium: {
    0: '#4F47BA',
    1: '#4B4B99',
    2: '#8C89B4',
    3: '#AEABD8',
  },
  light: '#CFCCED',
  light2: '#A5B1B9',
  dark: '#203D50',
} as const;

export default Secondary;
