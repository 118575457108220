const INDEXED_COLORS = [
  '#9268F5',
  '#24B2EF',
  '#F56C68',
  '#FFE177',
  '#ABD5FF',
  '#0573CE',
  '#078768',
  '#96D278',
  '#F9B067',
  '#FD6DC3',
];

const Dimensions = {
  title: '#2F80ED',
  background: '#0060FF',
  starBackground: '#FDF7CB',
  star: '#E8B900',
  instruction: {
    background: '#8E8EA0',
    dontBackground: '#F9E5E5',
  },
  indexed: INDEXED_COLORS,
  indexedLength: INDEXED_COLORS.length,
} as const;

export default Dimensions;
