import React, { ReactNode } from 'react';

import { LabelContainer } from './styledComponents';
import Typography from '../Typography';
import { Gray } from '../../themes/colors';

type Props = {
  label?: ReactNode;
  RightLabelComponent?: JSX.Element;
};

const InputLabel: React.FC<Props> = ({ label, RightLabelComponent }) => {
  return (
    <LabelContainer>
      {label && (
        <Typography variant="textSm" color={Gray[700]}>
          {label}
        </Typography>
      )}
      {RightLabelComponent}
    </LabelContainer>
  );
};

export default React.memo(InputLabel);
