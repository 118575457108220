const Green = {
  25: '#F6FFFD',
  50: '#E9FEF9',
  100: '#C5FBED',
  200: '#6FE0C3',
  300: '#31C09A',
  400: '#00B081',
  500: '#009170',
  600: '#007460',
  700: '#00574F',
  800: '#00393F',
  900: '#001C2F',
} as const;

const Indigo = {
  25: '#F5F8FF',
  50: '#EEF4FF',
  100: '#E0EAFF',
  200: '#C7D7FE',
  300: '#A4BCFD',
  400: '#8098F9',
  500: '#6172F3',
  600: '#444CE7',
  700: '#3538CD',
  800: '#2D31A6',
  900: '#2D3282',
} as const;

// auth use only (login/signup)
const Gradient = {
  Solid: {
    Green,
    Indigo,
  },
  Graphic: {
    2: `linear-gradient(300.24deg, ${Green[300]} 2.37%, #4E81CC 69.06%, ${Indigo[700]} 102.56%);`,
    4: 'linear-gradient(299.74deg, #479FB0 11.16%, #2E67BF 40.93%, #273ACC 63.9%, #6325CC 92.82%);',
    5: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(299.74deg, #479FB0 11.16%, #2E67BF 40.93%, #273ACC 63.9%, #6325CC 92.82%);',
  },
  700: 'linear-gradient(98.8deg, #009170 29.48%, #3538CD 133.95%)',
  900: 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(98.8deg, #009170 29.48%, #3538CD 133.95%)',
  Primary: {
    900: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(98.8deg, ${Green[500]} 29.48%, ${Indigo[700]} 133.95%)`,
  },
};

export default Gradient;
