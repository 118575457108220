import styled from 'styled-components';
import { Radio } from 'antd';

export type StyledRadioGroupProps = {
  gap?: number;
  flexColumn?: boolean;
  marginTop?: number;
};

export type StyledRadioProps = {
  marginRight?: number;
  $radioColor?: string;
};

export const StyledRadioGroup = styled(Radio.Group)<StyledRadioGroupProps>`
  margin-top: ${({ marginTop }) => `${marginTop}px`};
  display: flex;
  ${({ flexColumn }) => flexColumn && 'flex-direction: column'};
  ${({ gap }) => (gap ? `gap: ${gap}px` : '')}
`;

export const StyledRadio = styled(Radio)<StyledRadioProps>`
  margin-right: ${({ marginRight }) => `${marginRight ?? 39}px`};
  ${({ $radioColor }) =>
    $radioColor
      ? `
      & .ant-radio-inner {
        border-color: ${$radioColor};
      }

      & .ant-radio-checked .ant-radio-inner::after {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        margin-block-start: -10px;
        margin-inline-start: -10px;
        background-color: ${$radioColor};
      }

      & .ant-radio-checked .ant-radio-inner {
        background-color: transparent;
        border-color: ${$radioColor};
      }
      `
      : ''}
`;
