import { Table as AntTable } from 'antd';
import { baseColors } from 'shared/ui/theme';
import styled from 'styled-components';

export const StyledTable = styled(AntTable)`
  .ant-table {
    border-radius: 8px;
    box-shadow:
      0px 4px 4px rgba(110, 129, 141, 0.05),
      0px 0px 2px rgba(110, 129, 141, 0.1);
  }

  .ant-table-content {
    overflow-y: auto;
  }

  .ant-table-thead {
    & > tr > th {
      background: white;
      color: ${baseColors.secondaryText};
    }
  }
  .ant-table-container table > thead > tr:first-child {
    & th {
      padding-top: 24px;
      padding-bottom: 15px;
    }
    & th:first-child {
      border-top-left-radius: 8px;
    }
    & th:last-child {
      border-top-right-radius: 8px;
    }
  }
  .ant-table-container table > tbody > tr:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    & td:first-child {
      border-bottom-left-radius: 8px;
    }
    & td:last-child {
      border-bottom-right-radius: 8px;
    }
  }
  tr:nth-child(odd) {
    background-color: rgba(245, 252, 251, 0.4);
  }

  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-cell {
    padding-left: 32px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  & .ant-pagination-item {
    border-radius: 50%;
    height: 48px;
    width: 48px;
    border-color: transparent;
    & > a {
      font-weight: bold;
      margin-top: 7px;
    }
  }
  & .ant-pagination-item:not(.ant-pagination-item-active) {
    border: none;
    background-color: inherit;
  }

  & .ant-pagination-item-link .ant-pagination-item-ellipsis {
    color: ${baseColors.primaryAction};
  }

  & .ant-pagination-item-active {
    background-color: ${baseColors.primary};
    & > a {
      color: ${baseColors.neutral00};
    }
  }

  & .ant-pagination-disabled {
    opacity: 0.25;
  }

  & .ant-pagination-prev,
  .ant-pagination-next {
    height: 48px;
    width: 48px;
    & > a {
      border: none;
      background-color: ${baseColors.secondaryAction};
      border-radius: 50%;
      padding-top: 7px;
      svg {
        fill: ${baseColors.neutral00};
      }
    }
  }
`;
